import * as React from 'react'

import { Collapse, MenuItem, Stack, Theme, Typography } from '@mui/material'

import { NotesFilter } from '../notes/NotesFilter'
import * as notes from '../notes/redux'
import * as selectors from '../notes/selectors'
import { useRedux } from '../hooks/useRedux'
import { NewProjectButton } from './create/NewProjectButton'
import { ProjectItem } from './ProjectItem'
import { Project } from '../graphql/types'
import DrillDownMenu from '../UI/DrillDownList/DrillDownMenu'
import { NoteWithTemplate } from '../vvapi/models'
import NoteListItem from '../notes/NoteListItem'
import { Level } from '../UI/DrillDownList/types'
import i18n, { keys } from '../i18n'
import {
  deleteNotes,
  deleteProject,
  moveGeneralProjectNotes,
  moveProjectNotes,
} from './queries'
import { refreshListNotes, refreshListProjects } from '../notes/selectors'
import { Delete } from '@mui/icons-material'
import { makeStyles } from '@mui/styles'
import { MoveNotesModal } from './move/MoveNotesModal'
import { MoveMenuItem } from './move/MoveMenuItem'
import { SelectButton } from './select/SelectButton'
import { DeleteButton } from './select/DeleteButton'
import { MoveButton } from './select/MoveButton'
import { showFeedback } from '../redux/notifications/redux'
import { DeleteProjectModal } from './delete/DeleteProjectModal'
import { DeleteProjectNotesModal } from './delete/DeleteProjectNotesModal'
import { selectOrganizationId } from '../data/selectOrganizationId'
import { MoveProjectNotesModal } from './move/MoveProjectNotesModal'

const useStyles = makeStyles((theme: Theme) => ({
  option: {
    width: '170px',
    height: '32px',
  },
}))

export const ProjectsDrawer = () => {
  const classes = useStyles()
  const [state, dispatch] = useRedux()

  const organizationId = selectOrganizationId(state)
  const filteredNotes = selectors.selectFilteredNotes(state)
  const notesById = selectors.selectNotesById(state)
  const projectsById = selectors.selectProjectsById(state)
  const [initialLoadComplete, setInitialLoadComplete] = React.useState(false)
  const isFiltered = React.useMemo(() => {
    const { searchTerm, fromDate, toDate, formType, projectType } =
      state.notes.filterInfo
    return !!searchTerm || !!formType || !!fromDate || !!toDate || !!projectType
  }, [state.notes.filterInfo])
  const filteredProjects = React.useMemo(() => {
    if (!isFiltered) {
      return Object.values(projectsById)
    }
    const projectIds = new Set(
      filteredNotes.map((id) => notesById[id].projectId ?? 'general')
    )
    const projs = Object.values(projectsById).filter((project) =>
      projectIds.has(project.id)
    )
    return projs
  }, [isFiltered, filteredNotes, projectsById, notesById])
  const notesByProjectId = selectors.selectNotesByProjectId(state)
  const selectedProject = state.notes.selectedProjectIds

  React.useEffect(() => {
    const projectIds = Object.keys(projectsById)
    if (
      selectedProject.length === 0 &&
      projectIds.length > 0 &&
      !initialLoadComplete
    ) {
      dispatch(notes.actions.setSelectedProjectIds(projectIds))
      setInitialLoadComplete(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectsById])

  const [currentLevelIndex, setCurrentLevelIndex] = React.useState(0)

  const [noteMode, setNoteMode] = React.useState<'map' | 'select'>('map')
  const [selectedNotes, setSelectedNotes] =
    React.useState<Record<string, NoteWithTemplate>>()
  const [moveNotesModalOpen, setMoveNotesModalOpen] = React.useState(false)
  const onMoveNotes = async () => {
    setMoveNotesModalOpen(false)
    setSelectedNotes({})
  }
  const selectOptionsDisabled = React.useMemo(() => {
    return Object.keys(selectedNotes ?? {}).length === 0
  }, [selectedNotes])

  const [moveProjectNotesTarget, setMoveProjectNotesTarget] = React.useState<
    string | undefined
  >()
  const [moveProjectSelection, setMoveProjectSelection] = React.useState<
    string | undefined
  >()
  const moveNotesToProject = React.useCallback(async () => {
    const currentProject = projectsById[moveProjectSelection ?? '']
    if (!currentProject) {
      return
    }

    const { data: project } =
      currentProject.id === 'general'
        ? await moveGeneralProjectNotes(organizationId, moveProjectNotesTarget)
        : await moveProjectNotes(
            currentProject.id,
            moveProjectNotesTarget ?? 'general'
          )

    if (project) {
      refreshListProjects()
      refreshListNotes()
      setMoveProjectSelection(undefined)
      setMoveProjectNotesTarget(undefined)
      dispatch(
        showFeedback({
          message: i18n.t(keys.moveAllProjectNotesSuccessMessage),
          severity: 'success',
        })
      )
    } else {
      dispatch(
        showFeedback({
          message: i18n.t(keys.moveAllProjectNotesFailedMessage),
          severity: 'error',
        })
      )
    }
  }, [
    projectsById,
    moveProjectSelection,
    organizationId,
    moveProjectNotesTarget,
    dispatch,
  ])

  const [deleteProjectSelection, setDeleteProjectSelection] = React.useState<
    string | undefined
  >()
  const onDeleteProject = React.useCallback(
    async (project: Project) => {
      if (project.id === 'general') {
        return
      }

      const result = await deleteProject(project.id)
      if (result?.projectData) {
        refreshListProjects()
        refreshListNotes()
        dispatch(
          showFeedback({
            message: i18n.t(keys.deleteProjectSuccessMessage),
            severity: 'success',
          })
        )
        setDeleteProjectSelection(undefined)
      } else {
        dispatch(
          showFeedback({
            message: i18n.t(keys.deleteProjectFailedMessage),
            severity: 'error',
          })
        )
      }
    },
    [dispatch]
  )

  const [deleteProjectNotesModalOpen, setDeleteProjectNotesModalOpen] =
    React.useState(false)
  const onDeleteSelectedNote = React.useCallback(async () => {
    const result = await deleteNotes(Object.keys(selectedNotes ?? {}))

    if (result?.data) {
      refreshListNotes()
      refreshListProjects()
      setSelectedNotes({})
      setDeleteProjectNotesModalOpen(false)
      dispatch(
        showFeedback({
          message: i18n.t(keys.deleteNotesSuccessMessage),
          severity: 'success',
        })
      )
    } else {
      dispatch(
        showFeedback({
          message: i18n.t(keys.deleteNotesFailedMessage),
          severity: 'error',
        })
      )
    }
  }, [dispatch, selectedNotes])

  const handleProjectSelected = React.useCallback(
    (id: string) => {
      const selectedSet = new Set([...state.notes.selectedProjectIds])
      if (selectedSet.has(id)) {
        selectedSet.delete(id)
      } else {
        selectedSet.add(id)
      }
      dispatch(
        notes.actions.setSelectedProjectIds(Array.from(selectedSet.values()))
      )
    },
    [state.notes.selectedProjectIds, dispatch]
  )

  const projectLevel: Level = React.useMemo(
    () => ({
      id: 'projects',
      getItemComponent: (item) => {
        const project = item as Project
        return (
          <ProjectItem
            key={project.id}
            project={project}
            selected={selectedProject.includes(project.id)}
            onSelect={handleProjectSelected}
          />
        )
      },
      getItems: async () => {
        return filteredProjects
      },
      onDescend: async () => {
        setCurrentLevelIndex(1)
      },
      getOptions: (item) => {
        const menuItems = []
        const project = item as Project
        if (project.id !== 'general') {
          menuItems.push(
            <MenuItem onClick={() => setDeleteProjectSelection(project.id)}>
              <Stack
                className={classes.option}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography>{i18n.t(keys.deleteProjectLabel)}</Typography>
                <Delete />
              </Stack>
            </MenuItem>
          )
        }
        return [
          ...menuItems,
          <MoveMenuItem
            currentProject={project}
            projectOptions={projectsById}
            className={classes.option}
            moveNotesToProject={(newProjectId: string) => {
              setMoveProjectNotesTarget(newProjectId)
              setMoveProjectSelection(project.id)
            }}
          />,
        ]
      },
    }),
    [
      classes.option,
      filteredProjects,
      handleProjectSelected,
      projectsById,
      selectedProject,
    ]
  )

  const noteLevel: Level = React.useMemo(
    () => ({
      id: 'notes',
      getItemComponent: (item) => {
        const note = item as NoteWithTemplate
        return (
          <NoteListItem
            key={note.id}
            note={note}
            selected={!!selectedNotes?.[note.id]}
            mode={noteMode}
          />
        )
      },
      getBackLabel: (parent) => {
        const parentProject = parent as Project
        return parentProject?.name
      },
      onAscend: async () => {
        setCurrentLevelIndex(0)
        setNoteMode('map')
      },
      getItems: async (item) => {
        const parentProject = item as Project
        return filteredNotes.reduce((acc, curr) => {
          if (notesByProjectId[parentProject.id][curr]) {
            acc.push(notesByProjectId[parentProject.id][curr])
          }
          return acc
        }, [] as NoteWithTemplate[])
      },
      onSelectItem: (item) => {
        const note = item as NoteWithTemplate
        if (noteMode === 'map') {
          dispatch(
            notes.actions.setEditingNoteId({
              noteId: note.id,
              zoomToNote: true,
            })
          )
          return []
        } else {
          const selectedSet = { ...selectedNotes }
          if (selectedSet[note.id]) {
            delete selectedSet[note.id]
          } else {
            selectedSet[note.id] = note
          }
          setSelectedNotes(selectedSet)
          return Object.values(selectedSet)
        }
      },
    }),
    [notesByProjectId, filteredNotes, noteMode, dispatch, selectedNotes]
  )

  const levels = React.useMemo(
    () => [projectLevel, noteLevel],
    [projectLevel, noteLevel]
  )

  return (
    <>
      <Stack sx={{ overflowY: 'hidden', height: '100%' }}>
        <DrillDownMenu
          optionsComponent={
            <Stack>
              <NotesFilter
                style={{ display: noteMode === 'select' ? 'none' : 'inherit' }}
              />
              <Stack direction="column" px={2} py={1}>
                <Stack direction="row" alignItems="center" p={0}>
                  <NewProjectButton
                    type="notes"
                    style={{
                      display: currentLevelIndex === 0 ? 'inherit' : 'none',
                    }}
                  />
                  <SelectButton
                    noteMode={noteMode}
                    setNoteMode={setNoteMode}
                    style={{
                      display: currentLevelIndex === 0 ? 'none' : 'inherit',
                    }}
                  />
                </Stack>
                <Collapse in={noteMode === 'select'} orientation="vertical">
                  <Stack direction="row" alignItems="center" py={2} spacing={2}>
                    <DeleteButton
                      onPress={() => setDeleteProjectNotesModalOpen(true)}
                      disabled={selectOptionsDisabled}
                    />
                    <MoveButton
                      onPress={() => setMoveNotesModalOpen(true)}
                      disabled={selectOptionsDisabled}
                    />
                  </Stack>
                </Collapse>
              </Stack>
            </Stack>
          }
          levels={levels}
        />
      </Stack>
      <MoveNotesModal
        open={moveNotesModalOpen}
        onCancel={() => {
          setMoveNotesModalOpen(false)
        }}
        onMoveNotes={async () => {
          await onMoveNotes()
        }}
        selectedNotes={selectedNotes}
      />
      <MoveProjectNotesModal
        open={!!moveProjectSelection}
        onCancel={() => {
          setMoveProjectSelection(undefined)
          setMoveProjectNotesTarget(undefined)
        }}
        onMoveProjectNotes={moveNotesToProject}
        currentProjectName={projectsById[moveProjectSelection ?? '']?.name ?? ''}
        targetProjectName={projectsById[moveProjectNotesTarget ?? '']?.name ?? ''}
      />
      <DeleteProjectModal
        open={!!deleteProjectSelection}
        onCancel={() => setDeleteProjectSelection(undefined)}
        onProjectDelete={onDeleteProject}
        project={projectsById[deleteProjectSelection ?? '']}
      />
      <DeleteProjectNotesModal
        open={deleteProjectNotesModalOpen}
        onCancel={() => setDeleteProjectNotesModalOpen(false)}
        onProjectNotesDelete={onDeleteSelectedNote}
      />
    </>
  )
}
