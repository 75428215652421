import { merge } from 'lodash'
import { AnyAction, combineReducers, DeepPartial } from 'redux'

import * as app from '../app/reducer'
import { routerReducer } from '../appNavigation/routerRedux'
import * as AsyncSelector from '../AsyncSelector/reducer'
import * as colorScheme from '../colorScheme/reducer'
import * as userSelection from '../data/userSelectionRedux'
import * as geoPdfDownloads from '../geoPdfDownloads/redux'
import * as groupDownloads from '../groupDownloads/redux'
import * as noteForms from '../noteForms/redux'
import * as notes from '../notes/redux'
import * as postgis from '../postgis/reducer'
import * as ProductSettings from '../ProductSettings/store/redux'
import * as reports from '../reports/redux'
import { login } from '../vvapi/login'
import { APPLY_SETTINGS, FORCE_LOGOUT } from './actions'
import * as notifications from './notifications/redux'
import * as preferences from './preferences/redux'
import { RootStore } from './types'
import * as sampling from '../pages/SamplePlan/redux'

const combinedReducers = combineReducers<RootStore>({
  AsyncSelector: AsyncSelector.reducer,
  ProductSettings: ProductSettings.reducer,
  reports: reports.reducer,
  groupDownloads: groupDownloads.reducer,
  geoPdfDownloads: geoPdfDownloads.reducer,
  router: routerReducer,
  preferences: preferences.reducer,
  userSelection: userSelection.reducer,
  notifications: notifications.reducer,
  // old
  app: app.reducer, // moving to preferences
  colorScheme: colorScheme.reducer, // moving to UserProductSettings
  login: login.reducer,
  postgis: postgis.reducer,
  notes: notes.reducer,
  noteForm: noteForms.reducer,
  sampling: sampling.reducer,
})

const rootReducer = (
  state: RootStore | undefined,
  action: AnyAction
): RootStore => {
  switch (action.type) {
    case FORCE_LOGOUT: {
      return {
        ...state,
        ...combinedReducers(state, action),
        login: {
          isPostLoginInProgress: false,
          isInProgress: false,
          isLoggedIn: false,
          error: undefined,
        },
      }
    }
    case APPLY_SETTINGS: {
      const payload = action.state as Partial<RootStore>

      // use lodash's `merge` to apply deeply nested states
      const newState = merge<
        DeepPartial<RootStore>,
        RootStore | undefined,
        DeepPartial<RootStore>
      >(
        // apply to new object - being good redux citizens, we are careful not to change state directly
        {},
        state,
        payload as any
      )

      newState.settingsFetchedAt = Date.now()

      return newState
    }

    default:
      return {
        ...state,
        ...combinedReducers(state, action),
      }
  }
}

export default rootReducer
