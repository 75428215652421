import * as React from 'react'
import { Level, LevelInfo } from './types'
import { FixedSizeList } from 'react-window'
import useAsync from '../../hooks/useAsync'
import { Stack, Typography, useTheme } from '@mui/material'
import { LoadingControlBackdrop } from '../Loading/LoadingControlBackdrop'
import i18n, { keys } from '../../i18n'
import { DrillDownRow } from './DrillDownRow'

interface Props {
  level: Level
  levelIndex: number
  levels: Level[]
  setCurrentLevelInfo: (levelInfo: LevelInfo) => void
  currentLevelInfo: LevelInfo
  height: number
  width: number
}

export const DrillDownList = ({
  level,
  levelIndex,
  levels,
  setCurrentLevelInfo,
  currentLevelInfo,
  height,
  width,
}: Props) => {
  const theme = useTheme()
  const [fetchItems] = useAsync(async () => {
    const parentItem = currentLevelInfo.parentHistory[levelIndex]
    return (await level?.getItems?.(parentItem)) ?? []
  }, [currentLevelInfo.parentHistory[levelIndex], level.getItems])

  const levelItems = React.useMemo(
    () => fetchItems.result ?? [],
    [fetchItems.result]
  )

  if (fetchItems.status !== 'pending' && levelItems.length === 0) {
    return (
      level?.getEmptyListComponent?.() ?? (
        <Stack
          width={width}
          height={height}
          alignItems="center"
          justifyContent="center"
        >
          <Typography color={theme.palette.divider}>
            {i18n.t(keys.generic.noItems)}
          </Typography>
        </Stack>
      )
    )
  }

  return (
    <LoadingControlBackdrop open={fetchItems.status === 'pending'}>
      <FixedSizeList
        className={`${level.id}-level-list`}
        itemCount={levelItems.length}
        itemData={levelItems}
        itemSize={60}
        height={height}
        width={width}
        style={{
          overflowX: 'hidden',
          scrollbarColor: '#fff transparent',
          scrollbarWidth: 'none',
        }}
      >
        {({ index, style, data: items }) => {
          return (
            <DrillDownRow
              key={index}
              level={level}
              levels={levels}
              levelIndex={levelIndex}
              setCurrentLevelInfo={setCurrentLevelInfo}
              currentLevelInfo={currentLevelInfo}
              index={index}
              data={items}
              style={style}
              width={width}
            />
          )
        }}
      </FixedSizeList>
    </LoadingControlBackdrop>
  )
}
