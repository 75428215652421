import { Location } from 'history'
import * as React from 'react'
import { connect, DispatchProp } from 'react-redux'
import { useHistory } from 'react-router-dom'

import CssBaseline from '@mui/material/CssBaseline'
import {
  StyledEngineProvider,
  Theme,
  ThemeProvider,
} from '@mui/material/styles'

import { AppRoutes } from '../appNavigation/AppRoutes'
import { selectPreferredLanguage } from '../data/selectPreferredLanguage'
import { RootStore } from '../redux/types'
import ScrollToTop from '../ScrollToTop'
import { login } from '../vvapi/login'
import AppDialogs from './AppDialogs'
import ErrorBoundary from './ErrorBoundary'
import { themes } from './theme'
import useAsync from '../hooks/useAsync'
import { useRedux } from '../hooks/useRedux'
import { selectMe } from '../data/selectMe'
import { fetchUserAppNotifications } from '../data/fetchUserAppNotifications'
import { showNotification } from '../redux/notifications/redux'
import { useInterval } from '../hooks/useInterval'
import { postJson } from '../vvapi/apiResource/createApiResource'
import { notificationActionTypeArray } from '../graphql/types'
import { NotesTemplatesProvider } from '../notes/NotesContext'
import { FeatureFlagProvider } from '../feature-flags/FeatureFlagContext'

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const App = (props: ReduxProps & DispatchProp) => {
  const { theme, dispatch } = props
  const [state] = useRedux()

  const updateBodyTheme = (theme: keyof typeof themes) => {
    // if dark mode enabled, we want to disable it
    if (theme === 'dark') {
      // these classes are defined in src/index.css
      document.body.classList.remove('light')
      document.body.classList.add('dark')
    } else {
      // these classes are defined in src/index.css
      document.body.classList.remove('dark')
      document.body.classList.add('light')
    }
  }

  const history = useHistory()
  const [prevLocation, setPrevLocation] = React.useState<Location | undefined>(
    history.location
  )
  // ======== Notifications ========

  const me = selectMe(state)
  const [{ result: appNotifications }, refreshAppNotifications] = useAsync(
    fetchUserAppNotifications,
    [me?.id]
  )

  React.useEffect(() => {
    if (!appNotifications) {
      return
    }
    for (const notification of appNotifications) {
      dispatch(
        showNotification({
          ...notification.data,
          autoDismiss: false,
          appNotificationId: notification.id,
          type: notification.type,
        })
      )
    }
  }, [appNotifications, dispatch])

  useInterval(() => {
    refreshAppNotifications()
  }, 60000)

  // ======== Notifications End ========

  React.useEffect(() => {
    if (history) {
      // check initial location for #
      if (history.location.hash.startsWith('#/')) {
        history.replace(history.location.hash.replace('#', ''))
      }

      history.listen((location) => {
        // check each navigated location for #
        if (location.hash.startsWith('#/')) {
          history.replace(location.hash.replace('#', ''))
          return
        }

        const search = new URLSearchParams(location.search)
        if (search.has('apiUrl')) {
          setPrevLocation(location)
          return
        }
        if (prevLocation) {
          const prevSearch = new URLSearchParams(prevLocation.search)

          const prevApiUrl = prevSearch.get('apiUrl')
          if (prevApiUrl) {
            if (!!location.search) {
              history.replace(
                `${location.pathname}${location.search}&apiUrl=${prevApiUrl}`
              )
            } else {
              history.replace(`${location.pathname}?apiUrl=${prevApiUrl}`)
            }
          }
        }

        setPrevLocation(location)
      })
    }
  }, [history, prevLocation])

  React.useEffect(() => {
    props.dispatch(login.actions.tryPersistedKey())
    updateBodyTheme(props.theme)
  }, [props?.dispatch]) // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    updateBodyTheme(theme)
  }, [theme])

  React.useEffect(() => {
    const attemptSetNotificationAction = async () => {
      const search = new URLSearchParams(history.location.search)
      const notificationId = search.get('emailOpened')
      if (!!notificationId) {
        await postJson(
          `/api/v3/notifications/${notificationId}/action/${notificationActionTypeArray[0]}`
        )
        // Remove search param after usage.
        history.replace(
          `${history.location.pathname}${history.location.search.replace(
            `?emailOpened=${notificationId}`,
            ''
          )}`
        )
      }
    }
    if (props.isLoggedIn) {
      props.dispatch(login.actions.postLogin())
      // Attempt to set a notifications action
      attemptSetNotificationAction()
    }
  }, [props?.isLoggedIn]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ThemeProvider theme={themes[theme]} key={props.preferredLanguage}>
      <StyledEngineProvider injectFirst>
        <FeatureFlagProvider>
          <CssBaseline>
            <ErrorBoundary>
              <NotesTemplatesProvider>
                <AppRoutes />
                <AppDialogs />
                <ScrollToTop />
              </NotesTemplatesProvider>
            </ErrorBoundary>
          </CssBaseline>
        </FeatureFlagProvider>
      </StyledEngineProvider>
    </ThemeProvider>
  )
}

const mapState = (state: RootStore) => ({
  theme: state.preferences.theme,
  isLoggedIn: state.login.isLoggedIn,
  preferredLanguage: selectPreferredLanguage(state),
})
type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps>(
  mapState,
  undefined,
  undefined,
  // If the app component is pure then route changes don't
  // work. Just our luck, `connect` does its own pure logic check. So we have to
  // disable that here.
  { pure: false }
)(App)
