import * as dayjs from 'dayjs'
import * as React from 'react'

import { DateTimePicker, DateTimePickerProps } from '@mui/x-date-pickers'

import { DateLike } from '../../../i18n'
import { NameAndValue } from '../types'

interface Props
  extends Partial<Omit<DateTimePickerProps<Date>, 'value' | 'onChange'>> {
  name: string
  value?: string
  required?: boolean
  onChange(v: NameAndValue): void
  helperText?: string
}

class DateTimePickerYMDT extends React.PureComponent<Props> {
  render() {
    const { name, value, onChange, ...props } = this.props

    return (
      <DateTimePicker
        format="YYYY-MM-DD h:mm a"
        {...props}
        // dates from graphql are YMD
        value={value ? (dayjs(value) as any) : null}
        // dates must remain YMD to avoid timezone issues
        onChange={(date: Date) =>
          onChange({
            name,
            value: this.getDateTimeFormat(date),
          })
        }
      />
    )
  }

  getDateTimeFormat = (sendDate: DateLike) => {
    if (!sendDate) {
      return ''
    }
    return new Date(sendDate).toISOString()
  }
}

export default DateTimePickerYMDT
