import * as React from 'react'
import { useFeatureFlag } from '../hooks/useFeatureFlag'

interface Props {
  featureFlagId: string
  children: React.ReactNode | React.ReactNode[]
}

export const FeatureToggle = ({ featureFlagId, children }: Props) => {
  const { featureEnabled } = useFeatureFlag({ featureFlagId })

  if (!featureEnabled) {
    return null
  }

  return <React.Fragment>{children}</React.Fragment>
}
