import { LngLatLike } from 'mapbox-gl'
import * as React from 'react'

import {
  Box,
  Checkbox,
  Divider,
  Grid,
  Icon,
  IconButton,
  ListItem,
  Stack,
  Tooltip,
  Typography,
  styled,
} from '@mui/material'
import centroid from '@turf/centroid'

import i18n from '../i18n'
import { useMap } from '../map/withMap'
import { dispatch } from '../redux/store'
import { NoteWithTemplate } from '../vvapi/models'
import { actions } from './redux'

interface Props {
  note: NoteWithTemplate
  selected: boolean
  onClick?: (note: NoteWithTemplate) => void
  style?: React.CSSProperties
  mode?: 'map' | 'select'
}

const TitleText = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '21px',
  letterSpacing: '0.25px',
}))

const DescriptionText = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '15px',
  letterSpacing: '0.4px',
  color: theme.palette.text.primary,
  opacity: 0.7
}))

export const NoteListItem = ({ note, selected, style, mode }: Props) => {
  const createdAt = note.createdAt ? new Date(note.createdAt) : null

  const noteText = React.useMemo(() => {
    if (note?.templateName === '__VV_PINPOINT_CAPTURE__') {
      return note?.content?.label?.value || '';
    }

    const firstTextField = Object.values(note.content ?? {}).find(
      field => field.type === 'text',
    );

    if (firstTextField && firstTextField.value) {
      return firstTextField.value;
    }

    return i18n.t(`noteForm.reservedNames.${note.templateName}`, {
      defaultValue: note.templateName,
    });
  }, [note.content, note.templateName]);



  const { map } = useMap()

  const handleCenterMapOnNote = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation()
      if (note.feature.geometry.type === 'Point') {
        map?.panTo(note.feature.geometry.coordinates as LngLatLike)
        dispatch(actions.setFocusedNoteId({ noteId: note.id }))
      } else {
        const center = centroid({
          type: 'Feature',
          geometry: note.feature.geometry,
          properties: {},
        })
        map?.panTo(center.geometry.coordinates as LngLatLike)

        dispatch(actions.setFocusedNoteId({ noteId: note.id }))
      }
    },
    [map, note]
  )

  return (
    <ListItem disablePadding>
      <Grid
        container
        spacing={1}
        alignItems="start"
        sx={{ width: '100%' }}
        pl={2}
      >
        {mode === 'select' && (
          <Grid item xs={2}>
            <Checkbox checked={selected} sx={{ padding: 0}} />
          </Grid>
        )}
        <Grid item xs={mode === 'select' ? 8 : 10}>
          <Stack spacing={0.5}>
            <Stack direction="row" alignItems="center" spacing={1.5}>
              <Box
                className="pin-color"
                sx={{
                  height: 8,
                  width: 8,
                  borderRadius: 8,
                  background: `${note.pinColor}`,
                }}
              />
              <Tooltip title={noteText}>
                <TitleText noWrap>{noteText}</TitleText>
              </Tooltip>
            </Stack>
            <Stack direction="row" spacing={1}>
              <DescriptionText noWrap>
                {createdAt && i18n.toDateShort(createdAt)}
              </DescriptionText>
              <Divider
                orientation="vertical"
                flexItem
                style={{ background: '#FFFFFF80' }}
              />
              <Tooltip
                title={`${note.createdBy?.firstName ?? ''} ${
                  note.createdBy?.lastName ?? ''
                }`}
              >
                <DescriptionText noWrap>
                  {`${note.createdBy?.firstName ?? ''} ${
                    note.createdBy?.lastName ?? ''
                  }`}
                </DescriptionText>
              </Tooltip>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={2}>
          <IconButton onClick={handleCenterMapOnNote}>
            <Icon>fmd_good</Icon>
          </IconButton>
        </Grid>
      </Grid>
    </ListItem>
  )
}

export default NoteListItem
