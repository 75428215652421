import * as React from 'react'

import centroid from '@turf/centroid'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Stack,
  TextField,
} from '@mui/material'
import i18n, { keys } from '../i18n'

import { downloadCSV, downloadXLSX } from '../util/table'
import { NoteWithTemplate, OrgFeatureGeometry, User } from '../vvapi/models'
import areaUserString from './areaUserString'
import { selectFilteredNotes, selectNotesById } from './selectors'
import { useRedux } from '../hooks/useRedux'

interface Props {
  open: boolean
  onClose: () => void
}

export const ExportNotesDialog = ({ open, onClose }: Props) => {
  const [state] = useRedux()

  const notesById = selectNotesById(state)
  const filteredNoteIds = selectFilteredNotes(state)
  const preferredUnitSystem = state.preferences.preferredUnitSystem

  const [exportFileName, setExportFileName] = React.useState('Notes')
  const handleExportCSV = () => {
    const data = makeTableData()
    downloadCSV({ data, filename: exportFileName })
    onClose()
  }

  const handleExportXLSX = () => {
    const data = makeTableData()
    downloadXLSX({ data, filename: exportFileName })
    onClose()
  }

  const makeTableData = () => {
    const data = filteredNoteIds.map((id) => makeTableRow(notesById[id]))

    return data
  }

  const makeTableRow = (note: NoteWithTemplate) => {
    const {
      createdBy,
      updatedBy,
      feature: { geometry },
      content,
    } = note

    const centerPoint = centroid(geometry as any)
    const lng = centerPoint.geometry!.coordinates[0]
    const lat = centerPoint.geometry!.coordinates[1]

    let areaString = ''
    if (geometry.type === 'Polygon') {
      areaString = areaUserString(geometry, preferredUnitSystem)
    }

    return {
      Template: i18n.t(`noteForm.reservedNames.${note.templateName}`, {
        defaultValue: note.templateName,
      }),
      'Geometry Type': geometry.type,
      Area: areaString,
      Latitude: lat.toString(),
      Longitude: lng.toString(),
      Coordinates: JSON.stringify(getCoordinates(geometry)),
      'Created By': getName(createdBy),
      'Created At': note.createdAt,
      'Updated By': getName(updatedBy),
      'Updated At': note.updatedAt,
      ...mapContent(content),
    }
  }

  const mapContent = (content: NoteWithTemplate['content']) => {
    const mappedContent = {}
    Object.entries(content).map(([key, value]) => {
      if (value.type === 'checkbox') {
        mappedContent[key] = Boolean(value.value)
      } else {
        mappedContent[key] = value.value
      }
    })

    return mappedContent
  }
  const getName = (user?: User) => {
    return user && `${user.firstName} ${user.lastName}`
  }

  const getCoordinates = (geometry: OrgFeatureGeometry) => {
    let coordinates
    if (geometry.type === 'Polygon') {
      coordinates = geometry.coordinates[0]
    } else {
      coordinates = geometry.coordinates
    }

    return coordinates
  }

  return (
    <Dialog open={open} maxWidth="sm" onClose={onClose}>
      <DialogTitle>{i18n.t(keys.notes.exportBoxTitle)}</DialogTitle>
      <DialogContent sx={{ width: 280 }}>
        <Stack>
          <FormControl margin="normal" fullWidth>
            <TextField
              fullWidth
              autoFocus
              label={i18n.t(keys.notes.fileName)}
              value={exportFileName}
              onChange={(event) => setExportFileName(event.target.value)}
            />
          </FormControl>
        </Stack>
      </DialogContent>

      <DialogActions>
        <Stack direction="column" spacing={1} sx={{ width: '100%' }}>
          <Button onClick={onClose} variant="outlined" fullWidth>
            {i18n.t(keys.generic.cancel)}
          </Button>

          <Button
            fullWidth
            onClick={handleExportCSV}
            variant="contained"
            color="primary"
          >
            {i18n.t(keys.generic.csv)}
          </Button>

          <Button
            fullWidth
            onClick={handleExportXLSX}
            variant="contained"
            color="primary"
          >
            {i18n.t(keys.generic.xlsx)}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}
