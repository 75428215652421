import { url, urls } from '../../appNavigation/urls'
import * as React from 'react'
import i18n, { keys } from '../../i18n'
import MaestroHomeNavigation from './MaestroHomeNavigation'
import MaestroPage from './MaestroPage'

class MaestroHome extends React.PureComponent {
  render() {
    return (
      <MaestroPage
        title={`Maestro Dashboard`}
        backTo={url(urls.mapView)}
        backToTitle={i18n.t(keys.map.map)}
      >
        <div id="Maestro" style={{ padding: '1rem 2rem' }}>
          <div>
            <MaestroHomeNavigation />
          </div>

          {this.props.children}
        </div>
      </MaestroPage>
    )
  }
}

export default MaestroHome
