import * as React from 'react'
import { default as Dialog } from '@mui/material/Dialog'
import { default as DialogActions } from '@mui/material/DialogActions'
import { default as DialogContent } from '@mui/material/DialogContent'
import { default as DialogTitle } from '@mui/material/DialogTitle'
import { default as Typography } from '@mui/material/Typography'
import * as uuid4 from 'uuid/v4'
import errorAlert from '../../../admin/errorAlert'
import { NameAndValue } from '../../../admin/mapdata/types'
import assertDefinedFields from '../../../admin/mapdata/utils/assertDefinedFields'
import { Button } from '@mui/material'
import * as api from '../../../graphql/api'
import { Model } from '../../../graphql/types'
import i18n, { keys } from '../../../i18n'
import { objectPick } from '../../../util/objectPick'
import ColorProfileForm, { MapColorProfileData } from './ColorProfileForm'
import ColorProfilePreview from './ColorProfilePreview'
import updateOrCreateVisualizations from './visualizations/updateOrCreateVisualizations'

const getData = async (id: string) => {
  const colorProfile = await api.mapColorProfile.get<MapColorProfileData>({
    pk: { id },
    returning: `{
      id
      name
      description
      type
      display
      minLabel
      maxLabel
      noDataLabel
      coverageLabel
      dataStops
      noDataColor
      coverageColor
      MapColorProfileVisualizations {
        visualization
        order
        default
      }
      CreatedBy {
        firstName
      }
      createdAt
      UpdatedBy {
        firstName
      }
      updatedAt
    }`,
  })

  colorProfile.data!.dataStops = colorProfile.data!.dataStops.map(
    ([val, color]) => [val, color, uuid4()] as any
  )

  return colorProfile
}

interface Props {
  id: string
  open: boolean
  onSave(): void
  onClose(): void
}

type GQResponse = UnwrapPromise<ReturnType<typeof getData>>
type Results = NonNullable<GQResponse['data']>

interface State {
  colorProfile: Partial<Results>
}

class UpdateColorProfileDialog extends React.PureComponent<Props, State> {
  state: State = {
    colorProfile: {},
  }

  componentDidMount() {
    this.fetchData()
  }

  render() {
    const { colorProfile } = this.state
    const {
      CreatedBy,
      createdAt,
      updatedAt,
      UpdatedBy,
      MapColorProfileVisualizations: visualizations = [],
    } = colorProfile

    return (
      <Dialog
        id="UpdateColorProfileDialog"
        fullWidth
        open={!!this.props.open}
        onClose={this.props.onClose}
      >
        <DialogTitle>Edit Color Profile</DialogTitle>
        <DialogContent>
          <div className="grid">
            {CreatedBy && UpdatedBy && (
              <div className="grid-xs-12">
                <Typography variant="subtitle1">
                  Created By: {CreatedBy.firstName} - {createdAt}
                </Typography>
                <Typography variant="subtitle1">
                  Updated By: {UpdatedBy.firstName} - {updatedAt}
                </Typography>
              </div>
            )}
            <ColorProfileForm
              instance={colorProfile}
              onChange={this.handleChange}
              visualizations={visualizations}
            />
          </div>
        </DialogContent>
        <DialogActions className="align-right">
          <ColorProfilePreview {...colorProfile} />
          <Button onClick={this.props.onClose}>
            {i18n.t(keys.generic.cancel)}
          </Button>
          <Button variant="contained" color="primary" onClick={this.handleSave}>
            {i18n.t(keys.generic.save)}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  async fetchData() {
    const { id } = this.props

    try {
      const req = await getData(id)
      this.setState({
        colorProfile: req.data as any,
      })
    } catch (e) {
      softError(e, 'Fetching Error', 'Cannot find Color Profile')
    }
  }

  handleChange = ({ name, value }: NameAndValue) => {
    const { colorProfile } = this.state
    this.setState({
      colorProfile: {
        ...colorProfile,
        [name]: value,
      },
    })
  }

  handleSave = async () => {
    const { colorProfile } = this.state
    const { dataStops, MapColorProfileVisualizations: visualizations } =
      colorProfile

    const required = objectPick(colorProfile, ['name', 'type', 'display'])
    const optional = objectPick(colorProfile, [
      'description',
      'noDataColor',
      'coverageColor',
      'minLabel',
      'maxLabel',
      'noDataLabel',
      'coverageLabel',
    ])

    try {
      if (assertDefinedFields(required)) {
        const createdReq = await api.mapColorProfile.update<Model>({
          pk: { id: colorProfile.id! },
          input: {
            ...required,
            ...optional,
            dataStops: (dataStops ?? []).map(([val, color]) => [
              required.type === 'value' ? Number(val) : val,
              color,
            ]) as any,
          },
          returning: `{
            id
          }`,
        })

        if (createdReq.data && visualizations) {
          // add visualizations
          const mapColorProfileId = createdReq.data.id

          // no await; we don't want to keep the modal up if the
          // subsequent requests failed.
          updateOrCreateVisualizations({
            mapColorProfileId,
            visualizations,
          })
        }

        this.props.onSave()
      }
    } catch (e) {
      let message =
        'Please try again or contact us if you require additional assistance.'
      if (/GraphQL error/.test(e.message)) {
        message = e.message.replace(/GraphQL error:?\s?/, '')
      }
      softError(e, 'Failed to Create Color Profile', message)
    }
  }
}

const softError = (
  error: Error,
  title: string,
  message: string,
  extras?: Record<string, any>
) =>
  errorAlert({
    error,
    title,
    message,
    extras,
    tags: {
      category: 'map-data',
    },
  })

export default UpdateColorProfileDialog
