import * as React from 'react'
import { Route, RouteProps, Switch } from 'react-router-dom'

import JobListPage from '../admin/Maestro/JobListPage'
import JobPage from '../admin/Maestro/JobPage'
import PipelineListPage from '../admin/Maestro/PipelineListPage'
import PipelinePage from '../admin/Maestro/PipelinePage'
import DataSetDetailsPage from '../admin/ManageDataSets/DataSetDetailsPage'
import DataSetListPage from '../admin/ManageDataSets/DataSetListPage'
import EditMapLayerDef from '../admin/MapLayerDef/EditMapLayerDef'
import NewMapLayerDef from '../admin/MapLayerDef/NewMapLayerDef'
import { EditMapLayerDefGroup } from '../admin/MapLayerDefGroup/EditMapLayerDefGroup'
import { ListMapLayerDefsGroups } from '../admin/MapLayerDefGroup/ListMapLayerDefsGroups'
import { NewMapLayerDefGroup } from '../admin/MapLayerDefGroup/NewMapLayerDefGroup'
import EditMapSourceDef from '../admin/MapSourceDef/EditMapSourceDef'
import ListMapSourceDefs from '../admin/MapSourceDef/ListMapSourceDefs'
import NewMapSourceDef from '../admin/MapSourceDef/NewMapSourceDef'
import ListMonitorEvents from '../admin/Monitor/ListMonitorEvents'
import MonitorEventPage from '../admin/Monitor/MonitorEventPage'
import { MyQueue } from '../admin/MyQueue/MyQueue'
import EditPackage from '../admin/Package/EditPackage'
import ListPackages from '../admin/Package/ListPackages'
import NewPackage from '../admin/Package/NewPackage'
import { ProcessingDashboard } from '../admin/ProcessingDashboard/ProcessingDashboard'
import DeliveryGroupFileStatus from '../admin/Status/DeliveryGroupFilesStatus/DeliveryGroupFileStatus'
import DeliveryStatus from '../admin/Status/DeliveryStatus/DeliveryStatus'
import MigrationStatusPage from '../admin/Status/MigrationStatus/MigrationStatusPage'
import { YourQueue } from '../admin/YourQueue/YourQueue'
import withRouterParamsTracker from '../appNavigation/withRouterParamsTracker'
import { selectMe } from '../data/selectMe'
import { Downloads } from '../downloads/Downloads'
import { useFeatureFlag } from '../hooks/useFeatureFlag'
import Invitation from '../Invitation/Invitation'
import Register from '../Invitation/Register'
import { Notifications } from '../pages/account/notifications/Notifications'
import { AdminPage } from '../pages/admin/AdminPage'
import { AdminEdit } from '../pages/admin/admins/AdminEdit'
import { AdminNew } from '../pages/admin/admins/AdminNew'
import { Admins } from '../pages/admin/admins/AdminRoles'
import { FeatureFlagCreate } from '../pages/admin/features/FeatureFlagCreate'
import { FeatureFlagEdit } from '../pages/admin/features/FeatureFlagEdit'
import { FeatureFlags } from '../pages/admin/features/FeatureFlags'
import { ScheduledNotificationEdit } from '../pages/admin/notifications/ScheduledNotificationEdit'
import { ScheduledNotificationNew } from '../pages/admin/notifications/ScheduledNotificationNew'
import { ScheduledNotifications } from '../pages/admin/notifications/ScheduledNotifications'
import { OrganizationEdit } from '../pages/admin/organizations/OrganizationEdit'
import { OrganizationNew } from '../pages/admin/organizations/OrganizationNew'
import { Organizations } from '../pages/admin/organizations/Organizations'
import { VariableEdit } from '../pages/admin/variables/VariableEdit'
import { VariableNew } from '../pages/admin/variables/VariableNew'
import { Variables } from '../pages/admin/variables/Variables'
import EditDelivery from '../pages/Organization/Delivery/EditDelivery'
import NewDelivery from '../pages/Organization/Delivery/NewDelivery'
import { DeliveryProcGroup } from '../pages/Organization/Delivery/ProcessingGroups/DeliveryProcGroup'
import EditFlight from '../pages/Organization/Flight/EditFlight'
import NewFlight from '../pages/Organization/Flight/NewFlight'
import { ResendInvitation } from '../pages/Organization/Members/ResendInvitation'
import { RevokeInvitation } from '../pages/Organization/Members/RevokeInvitation'
import EditOrder from '../pages/Organization/Order/EditOrder'
import ListOrders from '../pages/Organization/Order/ListOrders/ListOrders'
import NewOrder from '../pages/Organization/Order/NewOrder'
import { OrganizationSettings } from '../pages/Organization/OrganizationSettings'
import EditTargetDelivery from '../pages/Organization/TargetDelivery/EditTargetDelivery'
import NewTargetDelivery from '../pages/Organization/TargetDelivery/NewTargetDelivery'
import { SamplePlanDashboard } from '../pages/SamplePlan/SamplePlanDashboard/SamplePlanDashboard'
import { SamplePlanDetail } from '../pages/SamplePlan/SamplePlanDetail/SamplePlanDetail'
import { SamplePlanResult } from '../pages/SamplePlan/SamplePlanResult/SamplePlanResult'
import MapView from '../postgis/MapView'
import Login from '../register/Login'
import ResetPassword from '../register/ResetPassword'
import ReportHome from '../reports/page/ReportHome'
import NotFound from './NotFound'
import { urls } from './urls'
import { useRedux } from '../hooks/useRedux'
import { ViewComment } from '../UI/CommentField/ViewComment'
import { LatestLogins } from '../pages/admin/logins/LatestLogins'

// tslint:disable-next-line: variable-name
export const AppRoutes = () => {
  const [state] = useRedux()

  const me = selectMe(state)
  const isLoggedIn = state.login.isLoggedIn

  const { featureEnabled: samplePlanEnabled } = useFeatureFlag({
    featureFlagId: 'sample-planning',
  })
  if (!isLoggedIn) {
    return anonymousRoutes()
  }

  if (me?.roles.includes('admin')) {
    return adminRoutes(samplePlanEnabled)
  }

  if (
    me?.roles.includes('data-processor-external') ||
    me?.roles.includes('data-processor')
  ) {
    return dataProcessorRoutes(samplePlanEnabled)
  }

  return nonAdminRoutes(samplePlanEnabled)
}

const anonymousRoutes = () => (
  <Switch>
    <Route
      exact
      path={urls.resetPassword.url}
      children={(props) => (
        <AppRouteElement {...props} component={ResetPassword} />
      )}
    />
    <Route
      exact
      path={urls.invitation.url}
      children={(props) => (
        <AppRouteElement {...props} component={Invitation} />
      )}
    />
    <Route
      exact
      path={urls.invitationNew.url}
      children={(props) => <AppRouteElement {...props} component={Register} />}
    />
    <Route
      exact
      path={urls.invitationExisting.url}
      children={(props) => <AppRouteElement {...props} component={Login} />}
    />
    <Route
      children={(props) => <AppRouteElement {...props} component={Login} />}
    />
  </Switch>
)

const nonAdminRoutes = (
  samplePlanEnabled: boolean,
  fallbackComponent?: React.ComponentType<any>
) => (
  <Switch>
    {/* Sample plan routes */}
    {samplePlanEnabled && (
      <Route
        path={urls.samplePlanResults.url}
        children={(props) => (
          <AppRouteElement {...props} component={SamplePlanResult} />
        )}
      />
    )}
    {samplePlanEnabled && (
      <Route
        path={urls.samplePlan.url}
        children={(props) => (
          <AppRouteElement {...props} component={SamplePlanDetail} />
        )}
      />
    )}
    {samplePlanEnabled && (
      <Route
        path={urls.samplePlans.url}
        children={(props) => (
          <AppRouteElement {...props} component={SamplePlanDashboard} />
        )}
      />
    )}
    <Route
      exact
      path={urls.account_notifications.url}
      children={(props) => (
        <AppRouteElement {...props} component={Notifications} />
      )}
    />
    <Route
      exact
      path={urls.resetPassword.url}
      children={(props) => (
        <AppRouteElement {...props} component={ResetPassword} />
      )}
    />
    <Route
      exact
      path={urls.report.url}
      children={(props) => (
        <AppRouteElement {...props} component={ReportHome} />
      )}
    />

    <Route
      exact
      path={urls.downloads.url}
      children={(props) => <AppRouteElement {...props} component={Downloads} />}
    />

    <Route
      exact
      path={urls.mapView.url}
      children={(props) => <AppRouteElement {...props} component={MapView} />}
    />
    <Route
      path={urls.organizationSettings.url}
      children={(props) => (
        <AppRouteElement {...props} component={OrganizationSettings} />
      )}
    />
    <Route
      path={urls.organizationRevokeInvite.url}
      children={(props) => (
        <AppRouteElement {...props} component={RevokeInvitation} />
      )}
    />
    <Route
      path={urls.organizationResendInvite.url}
      children={(props) => (
        <AppRouteElement {...props} component={ResendInvitation} />
      )}
    />
    <Route
      exact
      path={urls.invitation.url}
      children={(props) => (
        <AppRouteElement {...props} component={Invitation} />
      )}
    />
    <Route
      exact
      path={urls.invitationExisting.url}
      children={(props) => (
        <AppRouteElement {...props} component={Invitation} />
      )}
    />
    <Route
      path={urls.mapView.url}
      children={(props) => (
        <AppRouteElement {...props} component={fallbackComponent || MapView} />
      )}
    />

    <Route path="*" component={NotFound} />
  </Switch>
)

const dataProcessorRoutes = (samplePlanEnabled: boolean) => (
  <Switch>
    <Route
      exact
      path={urls.account_notifications.url}
      children={(props) => (
        <AppRouteElement {...props} component={Notifications} />
      )}
    />

    {/* Order */}
    <Route
      exact
      path={urls.listOrders.url}
      children={(props) => (
        <AppRouteElement {...props} component={ListOrders} />
      )}
    />
    <Route
      exact
      path={urls.editOrder.url}
      children={(props) => <AppRouteElement {...props} component={EditOrder} />}
    />

    {/* TargetDelivery */}
    <Route
      exact
      path={urls.newTargetDelivery.url}
      children={(props) => (
        <AppRouteElement {...props} component={NewTargetDelivery} />
      )}
    />
    <Route
      exact
      path={urls.editTargetDelivery.url}
      children={(props) => (
        <AppRouteElement {...props} component={EditTargetDelivery} />
      )}
    />

    {/* Flight */}
    <Route
      exact
      path={urls.newFlightFromDelivery.url}
      children={(props) => <AppRouteElement {...props} component={NewFlight} />}
    />
    <Route
      exact
      path={urls.editFlight.url}
      children={(props) => (
        <AppRouteElement {...props} component={EditFlight} />
      )}
    />

    <Route
      exact
      path={urls.deliveryProcGroup.url}
      children={(props) => (
        <AppRouteElement {...props} component={DeliveryProcGroup} />
      )}
    />
    <Route
      path={urls.editDelivery.url}
      children={(props) => (
        <AppRouteElement {...props} component={EditDelivery} />
      )}
    />

    <Route
      exact
      path={urls.monitorEvent.url}
      children={(props) => (
        <AppRouteElement {...props} component={MonitorEventPage} />
      )}
    />
    <Route
      exact
      path={urls.monitorEventList.url}
      children={(props) => (
        <AppRouteElement {...props} component={ListMonitorEvents} />
      )}
    />

    {/* Kato Queue */}
    <Route
      exact
      path={urls.myQueue.url}
      children={(props) => <AppRouteElement {...props} component={MyQueue} />}
    />

    {/* maestro */}
    <Route
      exact
      path={urls.pipeline.url}
      children={(props) => (
        <AppRouteElement {...props} component={PipelinePage} />
      )}
    />
    <Route
      exact
      path={urls.job.url}
      children={(props) => <AppRouteElement {...props} component={JobPage} />}
    />

    {/* Comment */}
    <Route
      exact
      path={urls.viewComment.url}
      children={(props) => (
        <AppRouteElement {...props} component={ViewComment} />
      )}
    />

    <Route>{nonAdminRoutes(samplePlanEnabled, NotFound)}</Route>
  </Switch>
)

const adminRoutes = (samplePlanEnabled: boolean) => (
  <Switch>
    <Route
      exact
      path={urls.account_notifications.url}
      children={(props) => (
        <AppRouteElement {...props} component={Notifications} />
      )}
    />

    {/* MapSourceDefGroup */}
    <Route
      exact
      path={urls.listLayerDefGroups.url}
      component={ListMapLayerDefsGroups}
    />
    <Route
      exact
      path={urls.newLayerDefGroup.url}
      children={(props) => (
        <AppRouteElement {...props} component={NewMapLayerDefGroup} />
      )}
    />
    <Route
      exact
      path={urls.editLayerDefGroup.url}
      children={(props) => (
        <AppRouteElement {...props} component={EditMapLayerDefGroup} />
      )}
    />
    {/* MapSourceDef */}
    <Route
      exact
      path={urls.listSourceDefs.url}
      children={(props) => (
        <AppRouteElement {...props} component={ListMapSourceDefs} />
      )}
    />
    <Route
      exact
      path={urls.newSourceDef.url}
      children={(props) => (
        <AppRouteElement {...props} component={NewMapSourceDef} />
      )}
    />
    <Route
      exact
      path={urls.editSourceDef.url}
      children={(props) => (
        <AppRouteElement {...props} component={EditMapSourceDef} />
      )}
    />

    {/* MapLayerDef */}
    <Route
      exact
      path={urls.newLayerDef.url}
      children={(props) => (
        <AppRouteElement {...props} component={NewMapLayerDef} />
      )}
    />
    <Route
      exact
      path={urls.newLayerDefFromSourceDef.url}
      children={(props) => (
        <AppRouteElement {...props} component={NewMapLayerDef} />
      )}
    />
    <Route
      exact
      path={urls.editLayerDef.url}
      children={(props) => (
        <AppRouteElement {...props} component={EditMapLayerDef} />
      )}
    />

    {/* Package */}
    <Route
      exact
      path={urls.listPackages.url}
      children={(props) => (
        <AppRouteElement {...props} component={ListPackages} />
      )}
    />
    <Route
      exact
      path={urls.newPackage.url}
      children={(props) => (
        <AppRouteElement {...props} component={NewPackage} />
      )}
    />
    <Route
      exact
      path={urls.editPackage.url}
      children={(props) => (
        <AppRouteElement {...props} component={EditPackage} />
      )}
    />

    {/* Order */}
    <Route
      exact
      path={urls.listOrders.url}
      children={(props) => (
        <AppRouteElement {...props} component={ListOrders} />
      )}
    />
    <Route
      exact
      path={urls.newOrder.url}
      children={(props) => <AppRouteElement {...props} component={NewOrder} />}
    />
    <Route
      exact
      path={urls.editOrder.url}
      children={(props) => <AppRouteElement {...props} component={EditOrder} />}
    />

    {/* TargetDelivery */}
    <Route
      exact
      path={urls.newTargetDelivery.url}
      children={(props) => (
        <AppRouteElement {...props} component={NewTargetDelivery} />
      )}
    />
    <Route
      exact
      path={urls.editTargetDelivery.url}
      children={(props) => (
        <AppRouteElement {...props} component={EditTargetDelivery} />
      )}
    />

    {/* Flight */}
    <Route
      exact
      path={urls.newFlightFromDelivery.url}
      children={(props) => <AppRouteElement {...props} component={NewFlight} />}
    />
    <Route
      exact
      path={urls.editFlight.url}
      children={(props) => (
        <AppRouteElement {...props} component={EditFlight} />
      )}
    />

    {/* Delivery */}
    <Route
      exact
      path={urls.newDelivery.url}
      children={(props) => (
        <AppRouteElement {...props} component={NewDelivery} />
      )}
    />
    <Route
      exact
      path={urls.deliveryProcGroup.url}
      children={(props) => (
        <AppRouteElement {...props} component={DeliveryProcGroup} />
      )}
    />
    <Route
      path={urls.editDelivery.url}
      children={(props) => (
        <AppRouteElement {...props} component={EditDelivery} />
      )}
    />

    {/* Kato Queue */}
    <Route
      exact
      path={urls.myQueue.url}
      children={(props) => <AppRouteElement {...props} component={MyQueue} />}
    />
    <Route
      exact
      path={urls.yourQueue.url}
      children={(props) => <AppRouteElement {...props} component={YourQueue} />}
    />

    {/* DataSet */}
    <Route
      exact
      path={urls.listDataSets.url}
      children={(props) => (
        <AppRouteElement {...props} component={DataSetListPage} />
      )}
    />
    <Route
      exact
      path={urls.editDataSet.url}
      children={(props) => (
        <AppRouteElement {...props} component={DataSetDetailsPage} />
      )}
    />

    {/* Status */}
    <Route
      exact
      path={urls.deliveryStatus.url}
      children={(props) => (
        <AppRouteElement {...props} component={DeliveryStatus} />
      )}
    />
    <Route
      exact
      path={urls.deliveryGroupFileStatus.url}
      children={(props) => (
        <AppRouteElement {...props} component={DeliveryGroupFileStatus} />
      )}
    />
    <Route
      exact
      path={urls.migrationStatus.url}
      children={(props) => (
        <AppRouteElement {...props} component={MigrationStatusPage} />
      )}
    />

    <Route
      exact
      path={urls.monitorEvent.url}
      children={(props) => (
        <AppRouteElement {...props} component={MonitorEventPage} />
      )}
    />
    <Route
      exact
      path={urls.monitorEventList.url}
      children={(props) => (
        <AppRouteElement {...props} component={ListMonitorEvents} />
      )}
    />

    {/* maestro */}
    <Route
      exact
      path={urls.pipeline.url}
      children={(props) => (
        <AppRouteElement {...props} component={PipelinePage} />
      )}
    />
    <Route
      exact
      path={urls.pipelines.url}
      children={(props) => (
        <AppRouteElement {...props} component={PipelineListPage} />
      )}
    />
    <Route
      exact
      path={urls.job.url}
      children={(props) => <AppRouteElement {...props} component={JobPage} />}
    />
    <Route
      exact
      path={urls.jobs.url}
      children={(props) => (
        <AppRouteElement {...props} component={JobListPage} />
      )}
    />
    <Route
      exact
      path={urls.admin_admins.url}
      children={(props) => <AppRouteElement {...props} component={Admins} />}
    />
    <Route
      exact
      path={urls.admin_admins_new.url}
      children={(props) => <AppRouteElement {...props} component={AdminNew} />}
    />
    <Route
      exact
      path={urls.admin_admins_edit.url}
      children={(props) => <AppRouteElement {...props} component={AdminEdit} />}
    />
    <Route
      exact
      path={urls.admin_organizations.url}
      children={(props) => (
        <AppRouteElement {...props} component={Organizations} />
      )}
    />
    <Route
      exact
      path={urls.admin_logins.url}
      children={(props) => (
        <AppRouteElement {...props} component={LatestLogins} />
      )}
    />
    <Route
      exact
      path={urls.admin_organizations_new.url}
      children={(props) => (
        <AppRouteElement {...props} component={OrganizationNew} />
      )}
    />
    <Route
      exact
      path={urls.admin_organizations_edit.url}
      children={(props) => (
        <AppRouteElement {...props} component={OrganizationEdit} />
      )}
    />
    <Route
      exact
      path={urls.admin_variables.url}
      children={(props) => <AppRouteElement {...props} component={Variables} />}
    />
    <Route
      exact
      path={urls.admin_variables_new.url}
      children={(props) => (
        <AppRouteElement {...props} component={VariableNew} />
      )}
    />
    <Route
      exact
      path={urls.admin_variables_edit.url}
      children={(props) => (
        <AppRouteElement {...props} component={VariableEdit} />
      )}
    />
    <Route
      exact
      path={urls.admin_notifications.url}
      children={(props) => (
        <AppRouteElement {...props} component={ScheduledNotifications} />
      )}
    />
    <Route
      exact
      path={urls.admin_notifications_new.url}
      children={(props) => (
        <AppRouteElement {...props} component={ScheduledNotificationNew} />
      )}
    />
    <Route
      exact
      path={urls.admin_notifications_edit.url}
      children={(props) => (
        <AppRouteElement {...props} component={ScheduledNotificationEdit} />
      )}
    />
    <Route
      exact
      path={urls.admin_feature_flags.url}
      children={(props) => (
        <AppRouteElement {...props} component={FeatureFlags} />
      )}
    />
    <Route
      exact
      path={urls.admin_feature_flags_new.url}
      children={(props) => (
        <AppRouteElement {...props} component={FeatureFlagCreate} />
      )}
    />
    <Route
      exact
      path={urls.admin_features_flags_edit.url}
      children={(props) => (
        <AppRouteElement {...props} component={FeatureFlagEdit} />
      )}
    />
    <Route
      exact
      path={urls.admin.url}
      children={(props) => <AppRouteElement {...props} component={AdminPage} />}
    />

    {/* Processing queue */}
    <Route
      path={urls.processingQueue.url}
      children={(props) => (
        <AppRouteElement {...props} component={ProcessingDashboard} />
      )}
    />

    {/* Comment Routes*/}
    <Route
      exact
      path={urls.viewComment.url}
      children={(props) => (
        <AppRouteElement {...props} component={ViewComment} />
      )}
    />

    <Route>{nonAdminRoutes(samplePlanEnabled, NotFound)}</Route>
  </Switch>
)

const mappedRoutes = new Map<RouteProps['component'], any>()

/** wraps each compoent with `withRouterParamsTracker(component)` */
// tslint:disable-next-line: variable-name
const AppRouteElement = ({ component, ...routeParams }: RouteProps) => {
  let TrackedComponent = mappedRoutes.get(component)
  if (!TrackedComponent) {
    TrackedComponent = withRouterParamsTracker(component)
    mappedRoutes.set(component, TrackedComponent)
  }

  return <TrackedComponent {...routeParams} />
}
