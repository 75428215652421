import * as React from 'react'
import ItemSelectionForm from '../../../UI/ItemSelection/ItemSelectionForm'
import SamplePlanReport from '../SamplePlanPDF/SamplePlanReport'
import { Parcel, SamplePlan } from '../../../graphql/types'
import { useRedux } from '../../../hooks/useRedux'
import i18n, { keys } from '../../../i18n'
import { selectOrganization } from '../../../data/selectOrganization'
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material'
import { downloadCSV } from '../../../util/table'
import { DownloadPlanCsvData, fetchSamplePlanDownloadCSVData } from '../queries'
import { selectPreferredLanguage } from '../../../data/selectPreferredLanguage'

export type DownloadSamplingFileTypes = 'pdf' | 'csv'

interface Props {
  downloadPlanOpen: boolean
  setDownloadPlanOpen: (open: boolean) => void
  samplePlan: SamplePlan | null
  parcels: Parcel[] | null
}

export const SamplePlanReportSelector = ({
  downloadPlanOpen,
  setDownloadPlanOpen,
  samplePlan,
  parcels,
}: Props) => {
  const [state] = useRedux()
  const preferredLanguage = selectPreferredLanguage(state)
  const [fileType, setFileType] =
    React.useState<DownloadSamplingFileTypes>('pdf')
  const [downloadReportOpen, setDownloadReportOpen] = React.useState(false)
  const [selectedParcelsForReport, setSelectedParcelsForReport] =
    React.useState<Parcel[]>()
  const organization = selectOrganization(state)

  const produceDownloadRows = async (
    samplePlan: SamplePlan | null,
    parcels: Parcel[] | null
  ): Promise<Record<string, any>[]> => {
    if (!samplePlan) {
      return []
    }

    const { data } = await fetchSamplePlanDownloadCSVData(samplePlan.id)

    const rows = data?.filter((row) =>
      parcels?.some((parcel) => parcel.id === row.parcelId)
    )

    const getFieldContentData = (
      content: Record<string, any>,
      fieldTranslations: Record<string, any>
    ) => {
      return Object.entries(content).reduce((acc, [key, value]) => {
        acc[`${fieldTranslations[key][preferredLanguage]}`] = value ?? ''
        return acc
      }, {})
    }

    const getDatasourceFlightData = (content: Record<string, any>) => {
      return {
        [`${i18n.t(keys.flightDate)}`]:
          Object.entries(content).find(
            ([_, value]) => value?.['flightDate'] !== undefined
          )?.[1]?.['flightDate'] ?? '',
      }
    }

    const getAllSampleCount = (row: DownloadPlanCsvData) => {
      return {
        [`${i18n.t(keys.samplePlanSamplesColumnLabel)}`]: Object.values(row.rowCounts).reduce((acc, value) => acc + value, 0)
      }
    }

    const getRowsWithCount = (row: DownloadPlanCsvData) => {
      const rowAndCounts = Object.entries(row.rowCounts)
        .map(([key, value]) => `${key}(${value})`)
        .join(',')
      const rows = Object.entries(row.rowCounts)
        .map(([key]) => `${key}`)
        .join(',')
      return {
        [`${i18n.t(keys.reports.rows)}`]: rows,
        [`${i18n.t(keys.samplePlanVinesPerRow)}`]: rowAndCounts,
      }
    }

    const sampleData =
      rows?.map(
        (s): Record<string, any> => ({
          [`${i18n.t(keys.user.organization)}`]: s.organizationName ?? '',
          [`${i18n.t(keys.samplePlanBlockLocationColumnLabel)}`]:
            s.organizationGroupName ?? '',
          [`${i18n.t(keys.sampleTemplate)}`]: s.sampleNoteTemplateName ?? '',
          [`${i18n.t(keys.samplePlanNewStatisticalMethodLabel)}`]:
            s.methodNameTranslation?.[preferredLanguage] ?? '',
          ...getDatasourceFlightData(s.statisticalMethodDataSources),
          [`${i18n.t(keys.samplePlanBlockIdColumnLabel)}`]: s.block ?? '',
          ...getFieldContentData(
            s.statisticalMethodFields,
            s.fieldTranslations
          ),
          ...getAllSampleCount(s),
          ...getRowsWithCount(s),
        })
      ) || []

    return sampleData
  }

  return (
    <>
      <ItemSelectionForm
        open={downloadPlanOpen}
        title={i18n.t(keys.samplePlanDownloadPlanFormTitle)}
        sectionTitle={i18n.t(keys.samplePlanDownloadPlanFormDescLabel)}
        items={parcels ?? []}
        onClose={() => setDownloadPlanOpen(false)}
        onCommit={async (parcels) => {
          setDownloadPlanOpen(false)
          setSelectedParcelsForReport(parcels ?? [])
          switch (fileType) {
            case 'pdf':
              setTimeout(() => {
                setDownloadReportOpen(true)
              }, 300)
              break
            case 'csv':
              const data = await produceDownloadRows(samplePlan, parcels)
              downloadCSV({
                filename: samplePlan?.name ?? 'sample-plan',
                data,
              })
              break
            default:
              break
          }
        }}
        additionalSections={
          {
            [i18n.t(keys.generic.downloadFormat)]: (<FormControl>
              <RadioGroup
                value={fileType}
                onChange={(e) =>
                  setFileType(e.target.value as DownloadSamplingFileTypes)
                }
                name="radio-buttons-group"
              >
                <FormControlLabel
                  value="pdf"
                  control={<Radio />}
                  label={i18n.t(keys.generic.pdf)}
                />
                <FormControlLabel
                  value="csv"
                  control={<Radio />}
                  label={i18n.t(keys.generic.csv)}
                />
              </RadioGroup>
            </FormControl>)
          }
        }
      />
      <SamplePlanReport
        open={downloadReportOpen}
        orgName={organization?.name ?? ''}
        samplePlan={samplePlan}
        selectedParcels={selectedParcelsForReport}
        onClose={() => {
          setDownloadReportOpen(false)
          setSelectedParcelsForReport([])
        }}
      />
    </>
  )
}
