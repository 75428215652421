import * as React from 'react'

import { Stack, Typography } from '@mui/material'

import { useRedux } from '../hooks/useRedux'
import NoteListItem from './NoteListItem'
import { NotesFilter } from './NotesFilter'
import * as notes from './redux'
import * as selectors from './selectors'
import i18n, { keys } from '../i18n'
import { useFeatureFlag } from '../hooks/useFeatureFlag'
import { ProjectsDrawer } from '../projects/ProjectsDrawer'
import AutoSizer from 'react-virtualized-auto-sizer'
import { FixedSizeList } from 'react-window'

export const NotesDrawer = () => {
  const [state, dispatch] = useRedux()

  // if projects is enabled, show the projects drawer instead
  const { featureEnabled } = useFeatureFlag({ featureFlagId: 'projects' })
  if (featureEnabled) {
    return <ProjectsDrawer />
  }

  const notesSelectors = selectors.selectListNotes(state)
  const filteredNotes = selectors.selectFilteredNotes(state)
  const notesById = selectors.selectNotesById(state)
  const editingNoteId = state.notes.editingNoteId

  const handleNoteClick = (id: string) => {
    dispatch(notes.actions.setEditingNoteId({ noteId: id, zoomToNote: true }))
  }

  return (
    <Stack sx={{ overflowY: 'hidden', height: '100%' }}>
      <NotesFilter />
      {notesSelectors.error ? (
        <Stack width="100%" alignItems="center">
          <Typography variant="caption" color="error">
            {i18n.t(keys.notes.failedToLoad)}
          </Typography>
        </Stack>
      ) : (
        <Stack sx={{ p: 1, height: '2000px', width: '100%' }}>
          <AutoSizer>
            {({ height, width }: { height: number; width: number }) => (
              <FixedSizeList
                height={height}
                width={width}
                itemSize={60}
                itemCount={filteredNotes.length}
                itemData={filteredNotes}
              >
                {({ index, style }) => {
                  const id = filteredNotes[index]
                  return (
                    <NoteListItem
                      key={id}
                      note={notesById[id]}
                      selected={id === editingNoteId}
                      onClick={() => handleNoteClick(id)}
                      style={style}
                    />
                  )
                }}
              </FixedSizeList>
            )}
          </AutoSizer>
        </Stack>
      )}
    </Stack>
  )
}
