import {
  setFocusedBlockId,
  setFocusedPoint,
  setFocusedPolygon,
  setFocusedSoilLayer,
} from '../../../../postgis/actions'
import {
  IMapActionGenerator,
  Priority,
  IMapAction,
} from '../types/MapActionTypes'
import { MapboxGL } from '../../../MapboxGL'
import { FocusedPolygon } from '../../../../postgis/types'
import { Feature } from '@turf/helpers'
import area from '@turf/area'
import { unionMany } from '../../../../util/unionMany'
import { IMapActionGeneratorParams } from '../types/MapActionGeneratorParams'

export class PolygonByColourActionGenerator implements IMapActionGenerator {
  key = 'polygon-by-colour'
  priority = Priority.PolygonByColour

  generateActionsFromQualifyingFeatures({
    dispatch,
    event,
    features,
    mapLayers,
    map,
    stats,
    state,
  }: IMapActionGeneratorParams): IMapAction[] {
    if (state.notes.editingNoteId !== undefined) {
      return []
    }
    if (!stats) {
      return []
    }
    const products = features.filter((f) => f.layer.id.endsWith('-layer'))
    // Find the qualifying features.
    const qualifiers = features.filter(
      (f: MapboxGL.MapboxGeoJSONFeature) =>
        (mapLayers?.[f.layer.id.replace('-layer', '')]?.mapLayerDef.dataProperty
          .type === 'value' &&
          isPolygonLayer(f)) ||
        isMultiPolygonLayer(f)
    )

    // Return functions that can execute the action for each of the qualifying features.
    return qualifiers.map((q) => ({
      key: this.key,
      priority: this.priority,
      execute: () => {
        let properties: FocusedPolygon['properties'] = {
          mapSourceId: q.sourceLayer,
        }

        const features = map.querySourceFeatures(q.source, {
          sourceLayer: q.sourceLayer,
        }) as Feature<GeoJSON.Polygon | GeoJSON.MultiPolygon>[]

        const mapLayerDefId =
          mapLayers[q.layer.id.replace('-layer', '')].mapLayerDefId
        const propertyValue =
          q.properties?.[
            mapLayers[q.layer.id.replace('-layer', '')].mapLayerDef.dataProperty
              .property
          ]

        const colorProfiles = Object.values(
          stats?.activeColorProfiles ?? {}
        ) as any[]

        const selectedColourProfile = colorProfiles.find(
          (acp) => acp.mapLayerDefId === mapLayerDefId
        )

        const greaterThanOrEqualToMaxDataStop =
          propertyValue >=
          selectedColourProfile.dataStops[
            selectedColourProfile.dataStops.length - 1
          ][0]

        const lessThanMinDataStop =
          propertyValue < selectedColourProfile.dataStops[0][0]

        const ceilingIndex = greaterThanOrEqualToMaxDataStop
          ? selectedColourProfile.dataStops.length - 1
          : selectedColourProfile.dataStops.findIndex(
              (ds: any[]) => propertyValue <= (ds[0] as number)
            )

        const floorIndex = lessThanMinDataStop
          ? 0
          : greaterThanOrEqualToMaxDataStop
          ? ceilingIndex
          : ceilingIndex - 1

        const floor = selectedColourProfile.dataStops[floorIndex][0]

        const floorFormatted =
          Math.round(
            (selectedColourProfile.dataStops[floorIndex][0] + Number.EPSILON) *
              100
          ) / 100

        // If the floor index is the ceiling of the available options, set it to the floor.
        const ceiling = selectedColourProfile.dataStops[ceilingIndex][0]

        const ceilingFormatted =
          Math.round(
            (selectedColourProfile.dataStops[ceilingIndex][0] +
              Number.EPSILON) *
              100
          ) / 100

        // Filter out the features that don't have the same color profile value.
        const filteredFeatures = features.filter((feat) => {
          const propertyValue =
            feat.properties?.[
              mapLayers[q.layer.id.replace('-layer', '')].mapLayerDef
                .dataProperty.property
            ]
          if (greaterThanOrEqualToMaxDataStop) {
            return propertyValue >= ceiling
          }

          if (lessThanMinDataStop) {
            return propertyValue <= floor
          }

          return propertyValue > floor && propertyValue <= ceiling
        })

        // get properties from other products
        products.forEach((product) => {
          if (!product) {
            return
          }

          properties = {
            ...properties,
            ...product.properties,
          }
        })

        const unionedFeature = unionMany(filteredFeatures)

        if (!!unionedFeature) {
          const focusedPolygon: Writable<FocusedPolygon> = {
            properties: {
              ...properties,
              Area_m2: area(unionedFeature),
              range: lessThanMinDataStop
                ? `≤${floorFormatted}`
                : greaterThanOrEqualToMaxDataStop
                ? `≥${ceilingFormatted}`
                : `${floorFormatted}-${ceilingFormatted}`,
            },
            type: 'Feature',
            geometry: unionedFeature.geometry,
            clickCoordinates: [event.lngLat.lng, event.lngLat.lat],
          }

          dispatch(setFocusedPoint(null))
          dispatch(setFocusedPolygon(focusedPolygon))
          dispatch(setFocusedBlockId(undefined))
          dispatch(setFocusedSoilLayer(null))
        }
      },
    }))
  }
}

/** type guard for polygon geometries */
const isPolygonLayer = (
  product: GeoJSON.Feature<GeoJSON.Geometry>
): product is GeoJSON.Feature<GeoJSON.Polygon> =>
  product.geometry.type === 'Polygon'

const isMultiPolygonLayer = (
  product: GeoJSON.Feature<GeoJSON.Geometry>
): product is GeoJSON.Feature<GeoJSON.MultiPolygon> =>
  product.geometry.type === 'MultiPolygon'
