import { client, gql } from '../graphql/client'
import { Note, Project } from '../graphql/types'
import { NoteWithTemplate } from '../vvapi/models'

const getProjectsQuery = gql`
  query GetProjects($organizationId: Int!, $type: String!) {
    data: Project(
      where: {
        _and: { organizationId: { _eq: $organizationId }, type: { _eq: $type } }
      }
    ) {
      id
      organizationId
      name
      description
      type
      createdAt
      updatedAt
    }
  }
`

const getProjects = (
  [organizationId]: [organizationId: string],
  skip: () => void
) => {
  try {
    if (!organizationId) {
      return skip()
    }
    return client.request<{ data: Project[] }>({
      query: getProjectsQuery,
      variables: { organizationId, type: 'notes' },
    })
  } catch (e) {
    console.error(e)
    return {
      data: undefined,
    }
  }
}

const createProjectMutation = gql`
  mutation CreateProject($project: Project_insert_input!) {
    data: insert_Project_one(object: $project) {
      id
      organizationId
      type
      description
      createdAt
      updatedAt
    }
  }
`

const createProject = (
  project: Partial<
    Pick<Project, 'name' | 'description' | 'type' | 'organizationId'>
  >
) => {
  try {
    if (!project.organizationId || !project.name || !project.type) {
      throw new Error('Missing required fields')
    }
    return client.request<{ data: Project }>({
      query: createProjectMutation,
      variables: { project },
    })
  } catch (e) {
    console.error(e)
    return {
      data: undefined,
    }
  }
}

const deleteProjectMutation = gql`
  mutation DeleteProject($projectId: uuid!, $deletedAt: timestamptz!) {
    projectData: update_Project_by_pk(
      pk_columns: { id: $projectId }
      _set: { deletedAt: $deletedAt }
    ) {
      id
    }
    noteData: update_Note(
      where: { projectId: { _eq: $projectId } }
      _set: { deletedAt: $deletedAt }
    ) {
      returning {
        id
      }
    }
  }
`

const deleteProject = (projectId: string) => {
  try {
    if (!projectId || projectId === 'general') {
      throw Error('Invalid project id')
    }
    return client.request<{ projectData: Project, noteData: Note[] }>({
      query: deleteProjectMutation,
      variables: {
        projectId,
        deletedAt: new Date().toISOString(),
      },
    })
  } catch (e) {
    console.error(e)
    return {
      projectData: undefined,
      noteData: undefined,
    }
  }
}

const moveProjectNotesMutation = gql`
  mutation MOVE_PROJECT_NOTES_TO_PROJECT(
    $originalProjectId: uuid!
    $newProjectId: uuid
  ) {
    data: update_Note(
      where: { projectId: { _eq: $originalProjectId } }
      _set: { projectId: $newProjectId }
    ) {
      returning {
        id
      }
    }
  }
`

const moveGeneralProjectNotesMutation = gql`
  mutation MOVE_GENERAL_NOTES_TO_PROJECT(
    $organizationId: Int!
    $newProjectId: uuid
  ) {
    data: update_Note(
      where: {
        _and: {
          organizationId: { _eq: $organizationId }
          projectId: { _is_null: true }
        }
      }
      _set: { projectId: $newProjectId }
    ) {
      returning {
        id
      }
    }
  }
`

const moveProjectNotes = (originalProjectId: string, newProjectId: string) => {
  try {
    if (!originalProjectId) {
      throw Error('Invalid project id')
    }
    return client.request<{ data: Project }>({
      query: moveProjectNotesMutation,
      variables: {
        originalProjectId,
        newProjectId: newProjectId === 'general' ? null : newProjectId,
      },
    })
  } catch (e) {
    console.error(e)
    return {
      data: undefined,
    }
  }
}

const moveGeneralProjectNotes = (
  organizationId: string | undefined,
  newProjectId: string | undefined
) => {
  try {
    if (!organizationId) {
      throw Error('Invalid organization id')
    }
    if (!newProjectId) {
      throw Error('Invalid project id')
    }
    return client.request<{ data: Project }>({
      query: moveGeneralProjectNotesMutation,
      variables: { organizationId, newProjectId },
    })
  } catch (e) {
    console.error(e)
    return {
      data: undefined,
    }
  }
}

const moveNotesMutation = gql`
  mutation MOVE_NOTES_TO_PROJECT($noteIds: [uuid!]!, $newProjectId: uuid) {
    data: update_Note(
      where: { id: { _in: $noteIds } }
      _set: { projectId: $newProjectId }
    ) {
      returning {
        id
      }
    }
  }
`

const moveNotes = (noteIds: string[], newProjectId?: string) => {
  try {
    if (!noteIds.length) {
      throw Error('Invalid note ids')
    }
    return client.request<{ data: { returning: NoteWithTemplate[] } }>({
      query: moveNotesMutation,
      variables: {
        noteIds,
        newProjectId: newProjectId === 'general' ? null : newProjectId,
      },
    })
  } catch (e) {
    console.error(e)
    return {
      data: undefined,
    }
  }
}

const deleteNotesMutation = gql`
  mutation DELETE_NOTES($noteIds: [uuid!], $now: timestamptz!) {
    data: update_Note(
      where: { id: { _in: $noteIds } }
      _set: { deletedAt: $now }
    ) {
      returning {
        id
      }
    }
  }
`

const deleteNotes = (noteIds: string[] | undefined) => {
  try {
    if (!noteIds?.length) {
      throw Error('Invalid note ids')
    }
    return client.request<{ data: { returning: NoteWithTemplate[] } }>({
      query: deleteNotesMutation,
      variables: { noteIds, now: new Date().toISOString() },
    })
  } catch (e) {
    console.error(e)
    return {
      data: undefined,
    }
  }
}

export {
  getProjects,
  createProject,
  deleteProject,
  moveProjectNotes,
  moveGeneralProjectNotes,
  moveNotes,
  deleteNotes,
}
