import * as React from 'react'
import { AnyAction, Dispatch } from 'redux'
import { connect } from '../redux/connect'
import { AppDispatchProps, RootStore } from '../redux/types'

// tslint:disable-next-line: variable-name
export const ReduxContext = React.createContext<
  [RootStore, Dispatch<AnyAction>]
>([] as any)

export const useRedux = () => React.useContext(ReduxContext)

const mapState = (state: RootStore) => {
  return {
    state,
  }
}

type ReduxProps = ReturnType<typeof mapState>

const provider = ({
  state,
  dispatch,
  children,
}: React.PropsWithChildren<ReduxProps & AppDispatchProps>) => (
  <ReduxContext.Provider value={[state, dispatch]} children={children} />
)

// tslint:disable-next-line: variable-name
export const ReduxContextProvider = connect<ReduxProps, {}, AppDispatchProps>(
  mapState
)(provider)
