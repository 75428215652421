import { default as Icon } from '@mui/material/Icon'
import { default as Input } from '@mui/material/Input'
import { default as InputLabel } from '@mui/material/InputLabel'
import * as uuid4 from 'uuid/v4'
import * as React from 'react'

import { randomColor } from './ColorPicker'
import FormColorPicker from './FormColorPicker'
import TooltipIconButton from './TooltipIconButton'

type Stop = [string | number, string] & { id?: string }

interface NameAndValue {
  name: string
  value?: Stop[]
}

interface Props extends NameAndValue {
  type: 'class' | 'value'
  label: string
  required?: boolean
  onChange(e: NameAndValue): void
}

export class FormColorStops extends React.PureComponent<Props> {
  render() {
    const { label, value = [], type, required = false } = this.props

    return (
      <div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <InputLabel required={required}>{label}</InputLabel>
          <TooltipIconButton
            tooltipProps={{
              placement: 'right',
            }}
            title={`Add a Stop!`}
            onClick={this.handleAddStop}
          >
            <Icon fontSize="small">add_circle</Icon>
          </TooltipIconButton>
        </div>

        <div className="multi-text-fields">
          {value.map((stop, i) => {
            const [val, color] = stop

            return (
              <Input
                key={stop.id}
                fullWidth
                type={type === 'value' ? 'number' : 'text'}
                name={String(i)}
                onChange={this.handleStopValueChange}
                value={val}
                autoComplete="off"
                startAdornment={
                  <div style={{ margin: '0px 8px' }}>
                    <FormColorPicker
                      name={String(i)}
                      value={color}
                      onChange={this.handleStopColorChange}
                    />
                  </div>
                }
                endAdornment={
                  <TooltipIconButton
                    tooltipProps={{
                      placement: 'right',
                    }}
                    title={`Remove !`}
                    onClick={() => this.handleRemoveStop(i)}
                  >
                    <Icon fontSize="small">close</Icon>
                  </TooltipIconButton>
                }
              />
            )
          })}
        </div>
      </div>
    )
  }

  onChange = (value: Stop[]) => {
    const { name, type, onChange } = this.props

    onChange({
      name,
      value:
        type === 'value'
          ? value.sort(([v1], [v2]) => Number(v1) - Number(v2))
          : value,
    })
  }

  handleAddStop = () => {
    const { value = [], type } = this.props
    const newStop: Stop = [
      type === 'value'
        ? value.length === 0
          ? 0
          : Number(value[value.length - 1][0]) + 0.1
        : `Stop ${value.length}`,
      randomColor(),
    ]
    newStop.id = uuid4()

    this.onChange([...value, newStop])
  }

  handleStopValueChange = (e: any) => {
    const { value = [] } = this.props
    const index = Number(e.target.name)
    const val = e.target.value

    const newStop: Stop = [val, value[index][1]]
    newStop.id = value[index].id || uuid4()

    this.onChange([
      ...value.slice(0, index),
      newStop,
      ...value.slice(index + 1),
    ])
  }

  handleStopColorChange = (e: any) => {
    const { value = [] } = this.props
    const index = Number(e.name)
    const color = e.value

    const newStop: Stop = [value[index][0], color]
    newStop.id = value[index].id || uuid4()

    this.onChange([
      ...value.slice(0, index),
      newStop,
      ...value.slice(index + 1),
    ])
  }

  handleRemoveStop = (index: number) => {
    const { value = [] } = this.props

    this.onChange([...value.slice(0, index), ...value.slice(index + 1)])
  }
}
