import { createSelector } from 'reselect'

import fuzzySearch from '../util/fuzzySearch'
import { isNumericIdEqual } from '../util/isNumericIdEqual'
import { selectDeliveryParcels } from './selectDelivery'
import { selectParcels } from './selectOrgMapData'
import { selectParcelFilter } from './selectParcelFilter'
import { selectParcelFilterInfo } from './selectParcelFilterInfo'

export const selectFilteredParcels = createSelector(
  [selectParcels, selectParcelFilterInfo, selectParcelFilter],
  (parcels, parcelFilterInfo, { data: parcelFilter }) => {
    let filterParcels = parcels

    if (parcelFilterInfo.advancedFilterOpen && parcelFilter) {
      filterParcels = filterParcels.filter(
        (parcel) =>
          !!parcelFilter.parcels.find(({ parcelId }) =>
            isNumericIdEqual(parcel.id, parcelId)
          )
      )
    }

    if (parcelFilterInfo.searchTerm.length > 0) {
      filterParcels = filterParcels.filter(
        (parcel) =>
          fuzzySearch(parcelFilterInfo.searchTerm, parcel.group.name) ||
          fuzzySearch(parcelFilterInfo.searchTerm, parcel.name)
      )
    }

    return filterParcels
  }
)

export const selectFilteredDeliveryParcels = createSelector(
  [selectDeliveryParcels, selectFilteredParcels],
  (deliveryParcels, filteredParcels) => {
    const parcelIds = new Set(filteredParcels.map(({ id }) => id))

    return deliveryParcels.filter(({ parcelId }) => parcelIds.has(parcelId))
  }
)
