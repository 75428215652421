import { createSelector } from 'reselect'

import { isNumericIdEqual } from '../util/isNumericIdEqual'
import { selectFilteredParcels } from './selectFilteredParcels'
import { selectGroups } from './selectOrgMapData'

export const selectFilteredGroups = createSelector(
  [selectGroups, selectFilteredParcels],
  (groups, filteredParcels) =>
    groups.filter((group) =>
      filteredParcels.some((parcel) =>
        isNumericIdEqual(parcel.groupId, group.id)
      )
    )
)
