import * as Sentry from '@sentry/browser'
import coalesce from '../../util/coalesce'
import { downloadURL } from '../../util/download'
import {
  deleteJson as _deleteJson,
  fetch,
  getBlob as _getBlob,
  getJson as _getJson,
  getText as _getText,
  postBlob as _postBlob,
  postJson as _postJson,
  putJson as _putJson,
} from '../../util/fetchJson'
import { BaseModel, PaginatedResults } from '../models'

export interface ApiResource<TModel> {
  list: (query?: Record<string, any>) => Promise<PaginatedResults<TModel>>
  create: (data: Writable<TModel>) => Promise<TModel>
  update: (data: Partial<TModel>) => Promise<TModel>
  get: (id: number) => Promise<TModel>
  destroy: (id: number) => Promise<undefined>
}

interface AuthFetchOptions {
  init?: RequestInit
  queryAuth?: boolean
}

export default function createApiResource<TModel extends BaseModel>(
  prefix: string
): ApiResource<TModel> {
  return {
    list(query) {
      const queryString = query
        ? Object.keys(query)
          .map((x) => {
            return `${x}=${encodeURIComponent(query[x])}`
          })
          .join('&')
        : ''

      const finalPrefix =
        prefix.indexOf('?') === -1
          ? `${prefix}?${queryString}`
          : `${prefix}&${queryString}`

      Sentry.addBreadcrumb({
        category: 'api',
        data: {
          prefix,
          method: 'list',
        },
      })

      return getJson<PaginatedResults<TModel>>(finalPrefix)
    },

    create(body) {
      Sentry.addBreadcrumb({
        category: 'api',
        data: {
          prefix,
          body,
          method: 'create',
        },
      })

      return postJson<TModel>(prefix, {
        body,
      })
    },

    update(body) {
      Sentry.addBreadcrumb({
        category: 'api',
        data: {
          prefix,
          body,
          method: 'update',
        },
      })

      return postJson<TModel>(`${prefix}/${body.id}`, {
        body,
      })
    },

    get(id) {
      Sentry.addBreadcrumb({
        category: 'api',
        data: {
          prefix,
          id,
          method: 'get',
        },
      })

      return getJson<TModel>(`${prefix}/${id}`)
    },

    destroy(id) {
      Sentry.addBreadcrumb({
        category: 'api',
        data: {
          prefix,
          id,
          method: 'destroy',
        },
      })

      return postJson<undefined>(`${prefix}/${id}/delete`)
    },
  }
}

type AuthToken = string | null

// tslint:disable-next-line: variable-name
let AUTH_TOKEN: AuthToken = null
// tslint:disable-next-line: variable-name
let CURRENT_ORG: string | undefined

export function setAuthToken(authToken: AuthToken) {
  AUTH_TOKEN = authToken
}

export function getAuthToken() {
  return AUTH_TOKEN
}

export function setCurrentOrg(organizationId: string) {
  CURRENT_ORG = organizationId
}

let apiUrl = coalesce(
  getApiUrlFromQuery(),
  process.env.REACT_APP_VV_API_URL,
  'http://localhost:5500'
)

export function setApiUrl(url: string) {
  apiUrl = url
}

export function getApiUrl() {
  return apiUrl
}

function getApiUrlFromQuery(): string | undefined {
  const matches = globalThis.location.search.match(/[?&]apiUrl=([^&]+)/)

  if (!matches) {
    return
  }

  // Decode the value from the query string and remove and trailing slashes /
  // hashes.
  // eslint-disable-next-line no-useless-escape
  return decodeURIComponent(matches[1]).replace(/[#\/]+$/g, '')
}

interface JsonRequest extends RequestInit {
  body?: any
}

/**
 * Overload for getJson that adds token to URL or headers.
 */
export function getJson<TJsonBody = any>(
  url: string,
  init: JsonRequest = {},
  returnHeaders = false
) {
  init.headers = new Headers(init.headers)
  addTokenToHeaders(init.headers)

  return _getJson<TJsonBody>(formatUrl(url), init, returnHeaders)
}

/**
 * Overload for getJson that adds token to URL or headers.
 */
export function getText(
  url: string,
  init: JsonRequest = {},
  returnHeaders = false
) {
  init.headers = new Headers(init.headers)
  addTokenToHeaders(init.headers)

  return _getText(formatUrl(url), init, returnHeaders)
}

export function getBlob(
  url: string,
  init: JsonRequest = {},
  returnHeaders = false
) {
  init.headers = new Headers(init.headers)

  return _getBlob(formatUrl(addAuthToUrl(url)), init, returnHeaders)
}

/**
 * Overload for postJson that adds token to URL or headers.
 */
export function postJson<TJsonBody = any>(url: string, init: JsonRequest = {}) {
  init.headers = new Headers(init.headers)
  addTokenToHeaders(init.headers)

  return _postJson<TJsonBody>(formatUrl(url), init)
}

export function putJson<TJsonBody = any>(url: string, init: JsonRequest = {}) {
  init.headers = new Headers(init.headers)
  addTokenToHeaders(init.headers)

  return _putJson<TJsonBody>(formatUrl(url), init)
}

export function deleteJson<TJsonBody = any>(
  url: string,
  init: JsonRequest = {}
) {
  init.headers = new Headers(init.headers)
  addTokenToHeaders(init.headers)

  return _deleteJson<TJsonBody>(formatUrl(url), init)
}

/**
 * Overload for postBlob that adds token to URL or headers.
 */
export function postBlob(url: string, init: JsonRequest = {}) {
  init.headers = new Headers(init.headers)
  addTokenToHeaders(init.headers)

  return _postBlob(formatUrl(url), init)
}

/**
 * Just add auth and apiUrl to the request;
 * this makes for less baby-sitting; just use fetch however you want;
 */
export function fetchWithAuth(url: string, options: AuthFetchOptions = {}) {
  const { init = {}, queryAuth = false } = options

  init.headers = new Headers(init.headers)
  let remoteUrl: string
  if (queryAuth) {
    remoteUrl = addAuthToUrl(url)
  } else {
    remoteUrl = url
    addTokenToHeaders(init.headers)
  }

  return fetch(formatUrl(remoteUrl), init)
}

/**
 * Authenticated fetch that shows an error
 */
export async function authFetchJSON(url: string, options?: AuthFetchOptions) {
  try {
    const res = await fetchWithAuth(url, options)

    if (!res.ok) {
      throw new Error('Bad Response')
    }

    return await res.json()
  } catch (err) {
    // tslint:disable-next-line: no-console
    console.warn(err)
    Sentry.addBreadcrumb({
      level: Sentry.Severity.Error,
      message: `Failed loading ${url}`,
    })
    Sentry.captureException(err)
    throw err
  }
}

/** Downloads a resource from an authenticated API */
export function downloadApiUrl(url: string, name?: string) {
  return downloadURL(getDownloadUrl(url, name), name)
}

export function getDownloadUrl(url: string, name?: string) {
  if (name) {
    return addParamToUrl(formatUrl(url), 'filenameOverride', name)
  }
  return formatUrl(url)
}

/** function to add all modifiers to the URL */
const formatUrl = (_url: string) => {
  let url = _url

  url = addApiUrl(url)
  url = addOrgToUrl(url)

  return url
}

/** prepends host */
const addApiUrl = (url: string) => `${apiUrl}${url}`

/** appends organizationId to querystring */
const addOrgToUrl = (url: string) => {
  if (!CURRENT_ORG) {
    return url
  }

  return addParamToUrl(url, 'organizationId', String(CURRENT_ORG))
}

const addAuthToUrl = (url: string) => {
  const authToken = getAuthToken()
  if (!authToken) {
    return url
  }

  return addParamToUrl(url, 'token', authToken)
}

const addParamToUrl = (url: string, key: string, value: string) => {
  const keyValue = `${encodeURIComponent(key)}=${encodeURIComponent(value)}`

  if (url.indexOf('?') < 0) {
    return `${url}?${keyValue}`
  }

  return `${url}&${keyValue}`
}

function addTokenToHeaders(headers: Headers): Headers {
  if (AUTH_TOKEN) {
    headers.set('Authorization', AUTH_TOKEN)
  }

  return headers
}
