import * as Sentry from '@sentry/browser'
import { throttle } from '../../util/throttle-debounce'

const throttleError = throttle({ delay: 200 }, (err: Error) => {
  Sentry.captureException(err)
})

const DEFAULT_INTERVAL = 1000

const INTEGER_SCALER = 1000000

export interface ValueInterval {
  value: number
  weight: number
  min: number
  max: number
}

export function createIntervalReducer(interval = DEFAULT_INTERVAL) {
  let result: Record<
    number,
    { sum: number; size: number; min: number; max: number }
  > = {}

  return {
    reset() {
      result = {}
    },
    update(data: { value: number; weight?: number }[]) {
      for (const { value, weight = 1 } of data) {
        if (weight <= 0 || isNaN(weight) || isNaN(value)) {
          const err = new Error(
            `Feature has value of ${value} and weight of ${weight}. NaN, weight <= 0 are bad values.`
          )
          // tslint:disable-next-line: no-console
          console.warn(err)
          Sentry.withScope((scope) => {
            scope.setTag('category', 'stats')
            scope.setTag('file', 'Reducer.ts')
            throttleError(err)
          })
          continue
        }

        const key = Math.floor(interval * value)
        const cr = (result[key] = result[key] ?? {
          sum: 0,
          size: 0,
          min: value,
          max: value,
        })

        cr.size += weight
        cr.sum += Math.round(weight * value * INTEGER_SCALER)
        cr.min = Math.min(cr.min, value)
        cr.max = Math.max(cr.max, value)
      }
    },
    digest(): ValueInterval[] {
      return Object.values(result)
        .map((i) => ({
          value: (i.sum / i.size) / INTEGER_SCALER,
          weight: i.size,
          min: i.min,
          max: i.max,
        }))
        .sort((a, b) => a.min - b.min)
    },
  }
}

export function createIntervalCombiner(interval = DEFAULT_INTERVAL) {
  let result: Record<
    number,
    { sum: number; size: number; min: number; max: number }
  > = {}

  return {
    reset() {
      result = {}
    },
    update(data: ValueInterval[]) {
      for (const { value, weight, min, max } of data) {
        const key = Math.floor(interval * value)
        const cr = (result[key] = result[key] ?? {
          min,
          max,
          sum: 0,
          size: 0,
        })

        cr.size += weight
        cr.sum += Math.round(weight * value * INTEGER_SCALER)
        cr.min = Math.min(cr.min, value)
        cr.max = Math.max(cr.max, value)
      }
    },
    digest(): ValueInterval[] {
      return Object.values(result)
        .map((i) => ({
          value: (i.sum / i.size) / INTEGER_SCALER,
          weight: i.size,
          min: i.min,
          max: i.max,
        }))
        .sort((a, b) => a.min - b.min)
    },
  }
}

export interface ClassInterval {
  value: number | string
  weight: number
}

export function createClassReducer() {
  let result: Record<number, ClassInterval> = {}

  return {
    reset() {
      result = {}
    },
    update(data: { value: number | string; weight?: number }[]) {
      for (const { value, weight = 1 } of data) {
        const valueString = String(value)
        const cr = (result[valueString] = result[valueString] ?? {
          value: valueString,
          weight: 0,
        })

        cr.weight += weight
      }
    },
    digest(): ClassInterval[] {
      return Object.values(result)
    },
  }
}

export function createClassCombiner() {
  let result: Record<number, ClassInterval> = {}

  return {
    reset() {
      result = {}
    },
    update(data: ClassInterval[]) {
      for (const { value, weight } of data) {
        const cr = (result[value] = result[value] ?? { value, weight: 0 })

        cr.weight += weight
      }
    },
    digest(): ClassInterval[] {
      return Object.values(result)
    },
  }
}
