import { Stack, Typography, styled, useTheme } from '@mui/material'
import * as React from 'react'
import {
  CompactCardContent,
  CompactCardHeader,
  FieldWithValue,
  TemplateFieldCard,
} from './NoteForm'
import { FixType, displayFixType } from '../util/displayFixType'
import { formatLocationError } from '../util/formatLocationError'
import { useRedux } from '../hooks/useRedux'
import { convertLength } from '../util/units/length'
import i18n, { keys } from '../i18n'

const ValueTypography = styled(Typography)({
  paddingLeft: 5,
})

export const PinPointButtonPress = ({
  content,
}: {
  content: Record<string, FieldWithValue>
}) => {
  const theme = useTheme()

  const [state] = useRedux()

  const preferredUnitSystem = state.preferences.preferredUnitSystem

  const formattedAltitude = React.useMemo(() => {
    if (content.altitude?.value === undefined) {
      return undefined
    }

    return convertLength(
      content.altitude.value as number,
      'meter',
      preferredUnitSystem,
      0.1
    )
  }, [content.altitude?.value, preferredUnitSystem])

  const formattedHMSL = React.useMemo(() => {
    if (content.hmsl?.value === undefined) {
      return undefined
    }

    return convertLength(
      content.hmsl.value as number,
      'meter',
      preferredUnitSystem,
      0.1
    )
  }, [content.hmsl?.value, preferredUnitSystem])

  const accuracy = React.useMemo(() => {
    if (content.horizontalAccuracy?.value === undefined) {
      return undefined
    }
    return formatLocationError(content.horizontalAccuracy.value as number)
  }, [content.horizontalAccuracy?.value])

  const calcedFixType = React.useMemo(() => {
    if (content.carrSoln?.value === 2) {
      return FixType.RTK_FIX
    }
    if (content.carrSoln?.value === 1) {
      return FixType.RTK_FLOAT
    }
    if (content.fixType?.value === 2 || content.fixType?.value === 3) {
      return FixType.GPS_FIX
    }
    if (content.fixType?.value === 4) {
      return FixType.DGNSS
    }

    return FixType.NOT_VALID
  }, [content.fixType?.value, content.carrSoln?.value])

  return (
    <Stack spacing={1}>
      <TemplateFieldCard
        sx={{ background: theme.palette.background.default }}
        elevation={0}
      >
        <CompactCardHeader
          title={i18n.t(
            keys.noteForm.reservedNames.__VV_PINPOINT_CAPTURE__Button
          )}
          titleTypographyProps={{
            fontWeight: '700 !important',
            letterSpacing: 0.4,
            px: 1 / 2,
          }}
        />
        <CompactCardContent>
          <ValueTypography>{content.button.value}</ValueTypography>
        </CompactCardContent>
      </TemplateFieldCard>
      <TemplateFieldCard
        sx={{ background: theme.palette.background.default }}
        elevation={0}
      >
        <CompactCardHeader
          title={i18n.t(
            keys.noteForm.reservedNames.__VV_PINPOINT_CAPTURE__Double
          )}
          titleTypographyProps={{
            fontWeight: '700 !important',
            letterSpacing: 0.4,
            px: 1 / 2,
          }}
        />
        <CompactCardContent>
          <ValueTypography>
            {content.double.value ? 'true' : 'false'}
          </ValueTypography>
        </CompactCardContent>
      </TemplateFieldCard>
      {accuracy && (
        <TemplateFieldCard
          sx={{ background: theme.palette.background.default }}
          elevation={0}
        >
          <CompactCardHeader
            title={i18n.t(
              keys.noteForm.reservedNames
                .__VV_PINPOINT_CAPTURE__Horizontal_Accuracy
            )}
            titleTypographyProps={{
              fontWeight: '700 !important',
              letterSpacing: 0.4,
              px: 1 / 2,
            }}
          />
          <CompactCardContent>
            <ValueTypography>{accuracy}</ValueTypography>
          </CompactCardContent>
        </TemplateFieldCard>
      )}
      <TemplateFieldCard
        sx={{ background: theme.palette.background.default }}
        elevation={0}
      >
        <CompactCardHeader
          title={i18n.t(
            keys.noteForm.reservedNames
              .__VV_PINPOINT_CAPTURE__Number_of_Satellites
          )}
          titleTypographyProps={{
            fontWeight: '700 !important',
            letterSpacing: 0.4,
            px: 1 / 2,
          }}
        />
        <CompactCardContent>
          <ValueTypography>{content.numSV.value}</ValueTypography>
        </CompactCardContent>
      </TemplateFieldCard>
      {formattedAltitude && (
        <TemplateFieldCard
          sx={{ background: theme.palette.background.default }}
          elevation={0}
        >
          <CompactCardHeader
            title={i18n.t(
              keys.noteForm.reservedNames.__VV_PINPOINT_CAPTURE__Altitude
            )}
            titleTypographyProps={{
              fontWeight: '700 !important',
              letterSpacing: 0.4,
              px: 1 / 2,
            }}
          />
          <CompactCardContent>
            <ValueTypography>{`${formattedAltitude.value.toFixed(2)} ${
              formattedAltitude.symbol
            }`}</ValueTypography>
          </CompactCardContent>
        </TemplateFieldCard>
      )}
      {formattedHMSL && (
        <TemplateFieldCard
          sx={{ background: theme.palette.background.default }}
          elevation={0}
        >
          <CompactCardHeader
            title={i18n.t(
              keys.noteForm.reservedNames.__VV_PINPOINT_CAPTURE__HMSL
            )}
            titleTypographyProps={{
              fontWeight: '700 !important',
              letterSpacing: 0.4,
              px: 1 / 2,
            }}
          />

          <CompactCardContent>
            <ValueTypography>{`${formattedHMSL.value.toFixed(2)} ${
              formattedHMSL.symbol
            }`}</ValueTypography>
          </CompactCardContent>
        </TemplateFieldCard>
      )}
      <TemplateFieldCard
        sx={{ background: theme.palette.background.default }}
        elevation={0}
      >
        <CompactCardHeader
          title={i18n.t(
            keys.noteForm.reservedNames.__VV_PINPOINT_CAPTURE__Fix_Type
          )}
          titleTypographyProps={{
            fontWeight: '700 !important',
            letterSpacing: 0.4,
            px: 1 / 2,
          }}
        />
        <CompactCardContent>
          <ValueTypography>{displayFixType(calcedFixType)}</ValueTypography>
        </CompactCardContent>
      </TemplateFieldCard>
      <TemplateFieldCard
        sx={{ background: theme.palette.background.default }}
        elevation={0}
      >
        <CompactCardHeader
          title={i18n.t(
            keys.noteForm.reservedNames.__VV_PINPOINT_CAPTURE__Date_Time
          )}
          titleTypographyProps={{
            fontWeight: '700 !important',
            letterSpacing: 0.4,
            px: 1 / 2,
          }}
        />
        <CompactCardContent>
          <ValueTypography>
            {`${new Date(
              content.date.value as string
            ).toLocaleDateString()} ${new Date(
              content.date.value as string
            ).toLocaleTimeString()}`}
          </ValueTypography>
        </CompactCardContent>
      </TemplateFieldCard>
    </Stack>
  )
}
