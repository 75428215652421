import { Stack, Typography, styled } from '@mui/material'
import * as React from 'react'
import i18n, { keys } from '../../i18n'

interface Props {
  currentProjectName: string
  targetProjectName: string
}

const MessageText = styled(Typography)`
  font-size: 16px;
  font-weight: 600;
  line-height: 19.2px;
  letter-spacing: -0.035em;
  text-align: left;
`

export const MoveProjectNotesForm = ({
  currentProjectName,
  targetProjectName,
}: Props) => {
  return (
    <Stack direction="column" gap={2} width={300}>
      <MessageText>
        {i18n.t(keys.moveAllProjectNotesPromptMessage, {
          currentProjectName,
          targetProjectName,
        })}
      </MessageText>
    </Stack>
  )
}
