import { createSelector } from 'reselect'

import { FeatureCollection, Polygon } from '@turf/helpers'

import { createAsyncSelector } from '../../AsyncSelector/createAsyncSelector'
import { selectMapLayerDefsById } from '../../data/selectListMapSourceDefs'
import { selectSelectedMapLayersArray } from '../../data/selectSelectedMapLayers'
import { selectSelectedMapLayersByLayerDefId } from '../../data/selectSelectedMapLayersByLayerDefId'
import { MapLayerData, MapLayerDefData } from '../../data/types'
import { selectStatsZones } from '../../postgis/selectors/selectStatsZones'
import { selectProductSettings } from '../../ProductSettings/store/selectors/selectProductSettings'
import { ProductSettings } from '../../ProductSettings/store/types'
import { RootStore } from '../../redux/types'
import { UnitSystem } from '../../util/units/unitSystem'
import { calculateStatsOnWorker } from '../calculateStats/statsWorker'
import { StatsOutputs } from '../calculateStats/types'

export const selectProductNumberOfGroups = createSelector(
  [selectProductSettings],
  (productSettings) => {
    const productNumberOfGroups = {}
    for (const [productId, settings] of Object.entries(productSettings)) {
      if (!settings) {
        continue
      }

      productNumberOfGroups[productId] = settings.numberOfGroups
    }

    return productNumberOfGroups
  }
)

const EMPTY_PRODUCT_COVERAGE: Record<string, number> = {}

export const selectProductCoverage = createSelector(
  [selectProductSettings],
  (productSettings) => {
    const productCoverage = {}
    for (const [productId, settings] of Object.entries(productSettings)) {
      if (!settings) {
        continue
      }

      productCoverage[productId] = settings.coverage ?? EMPTY_PRODUCT_COVERAGE
    }

    return productCoverage
  }
)

export const selectLayerIds = createSelector(
  [selectSelectedMapLayersArray],
  (layers) => {
    return layers.map((layer) => layer.id)
  }
)

const calcStats = async ({
  layerIds,
  mapLayerDefsById,
  productSettings,
  preferredUnitSystem,
  selectedMapLayersByLayerDefId,
  statsZones
}: {
  mapLayerDefsById: Record<any, MapLayerDefData>
  selectedMapLayersByLayerDefId: Record<any, MapLayerData>
  productSettings: Record<string, ProductSettings | undefined>
  preferredUnitSystem: UnitSystem
  layerIds: string[],
  statsZones?: FeatureCollection<Polygon>
}) => {
  try {
    const data = await calculateStatsOnWorker({
      mapLayerDefsById,
      selectedMapLayersByLayerDefId,
      productSettings,
      preferredUnitSystem,
      layerIds,
      statsZones
    })

    return data
  } catch (error) {
    // tslint:disable-next-line: no-console
    console.warn(`Error generating stats: ${error}`)

    return
  }
}

export const {
  selector: selectCalculateStats,
  refresh: refreshCalculateStats,
} = createAsyncSelector({
  resource: 'me.organization.delivery.generateStats',
  inputs: {
    mapLayerDefsById: (state: RootStore) => selectMapLayerDefsById(state),
    selectedMapLayersByLayerDefId: (state: RootStore) =>
      selectSelectedMapLayersByLayerDefId(state),
    productSettings: (state: RootStore) => selectProductSettings(state),
    preferredUnitSystem: (state: RootStore) =>
      state.preferences.preferredUnitSystem,
    layerIds: (state: RootStore) => selectLayerIds(state),
    statsZones: (state: RootStore) => selectStatsZones(state)
  },
  fetcher: calcStats,
})

const EMPTY_STATS: Partial<StatsOutputs> = {}

export const selectStats = createSelector(
  selectCalculateStats,
  ({ data }) => data ?? EMPTY_STATS
)

export const selectConvertedSelectedColorProfiles = createSelector(
  selectStats,
  (statsResults) => {
    return statsResults.convertedColorProfiles ?? {}
  }
)

export const selectConvertedProductColorProfilesByVisualizations =
  createSelector(selectStats, (statsResults) => {
    return statsResults.convertedColorProfilesByVisualization ?? {}
  })

export const selectActiveColorProfiles = createSelector(
  selectStats,
  (statsResults) => {
    return statsResults.activeColorProfiles ?? {}
  }
)

export const selectBestUnitsByProductId = createSelector(
  selectStats,
  (statsResults) => {
    return statsResults.bestUnitsByProductId ?? {}
  }
)
