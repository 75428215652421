import { createSelector } from 'reselect'
import { RootStore } from '../redux/types'
import { TableFilter, TableOrder } from '../UI/Table/types'

export const adminRoot = '/admin'

export const PAGE_SIZE_OPTIONS = [25, 50, 100, 200]
export const DEFAULT_PAGE_SIZE = PAGE_SIZE_OPTIONS[0]
export const DEFAULT_PAGE_INFO = {
  count: 0,
  page: 0,
  pageSize: DEFAULT_PAGE_SIZE,
}
const DEFAULT_PAGE_QUERY = {
  page: 0,
  pageSize: DEFAULT_PAGE_SIZE,
  orderBy: '',
  filter: '',
}

export const getDefaultPageInfo = ({
  info = DEFAULT_PAGE_INFO,
}: {
  info?: typeof DEFAULT_PAGE_INFO & {
    order?: TableOrder
    filter?: TableFilter
  }
} = {}) => info

const makeUrl = <
  P extends UrlParams = UrlParams,
  Q extends UrlQuery = UrlQuery
>(
  info: UrlInfo<P, Q>
) => info

export const urls = {
  account_notifications: makeUrl({
    url: '/account/notifications',
  }),

  admin: makeUrl({ url: '/admin' }),
  admin_admins: makeUrl({ url: '/admin/admins' }),
  admin_admins_new: makeUrl({ url: '/admin/admins/new' }),
  admin_admins_edit: makeUrl<{ userId: string; role: string }>({
    url: '/admin/admins/:userId/:role',
  }),

  admin_organizations: makeUrl({
    url: '/admin/organizations',
  }),
  admin_logins: makeUrl({
    url: '/admin/logins',
  }),
  admin_organizations_new: makeUrl({ url: '/admin/organizations/new' }),
  admin_organizations_edit: makeUrl<{ organizationId: string | number }>({
    url: '/admin/organizations/:organizationId',
  }),

  admin_variables: makeUrl({
    url: '/admin/variables',
  }),
  admin_variables_new: makeUrl({ url: '/admin/variables/new' }),
  admin_variables_edit: makeUrl<{ key: string | number }>({
    url: '/admin/variables/:key',
  }),

  admin_notifications: makeUrl({
    url: '/admin/notifications',
  }),
  admin_notifications_new: makeUrl({ url: '/admin/notifications/new' }),
  admin_notifications_edit: makeUrl<{ id: string | number }>({
    url: '/admin/notifications/:id',
  }),

  admin_feature_flags: makeUrl({
    url: '/admin/feature-flags',
  }),
  admin_feature_flags_new: makeUrl({ url: '/admin/feature-flags/new' }),
  admin_features_flags_edit: makeUrl<{ id: string | number }>({
    url: '/admin/feature-flags/:id',
  }),

  downloads: makeUrl({ url: '/downloads' }),
  mapDataRoot: makeUrl({ url: adminRoot }),
  mapView: makeUrl({ url: '/' }),
  organizationSettings: makeUrl<{ organizationId: string | number }>({
    url: '/teams/:organizationId',
  }),
  organizationResendInvite: makeUrl<{
    organizationId: string | number
    inviteEmail: string
  }>({
    url: '/resend-invite/:organizationId/:inviteEmail',
  }),
  organizationRevokeInvite: makeUrl<{
    organizationId: string | number
    inviteEmail: string
  }>({
    url: '/revoke-invite/:organizationId/:inviteEmail',
  }),
  organizationSettingsSubscriptionDirect: makeUrl<{
    organizationId: string | number
  }>({
    url: '/organizations/:organizationId/manage-subscription',
  }),
  resetPassword: makeUrl<{ token: string }>({ url: '/reset-password/:token' }),
  invitation: makeUrl<{ token: string }>({ url: '/invitation/:token' }),
  invitationExisting: makeUrl<{ token: string }>({
    url: '/invitation/:token/existing',
  }),
  invitationNew: makeUrl<{ token: string }>({ url: '/invitation/:token/new' }),
  report: makeUrl({ url: '/report' }),
  subscriptionConfirmation: makeUrl({ url: '/subscription-confirmation' }),

  // dataSet
  listDataSets: makeUrl<{ notesFilter?: string; packageFilter?: string }>({
    url: `${adminRoot}/data-sets/list`,
    defaultQuery: DEFAULT_PAGE_QUERY,
  }),
  editDataSet: makeUrl<{ dataSetId: string; tab: 'organizations' | 'images' }>({
    url: `${adminRoot}/data-sets/:dataSetId/:tab`,
    defaultParams: {
      tab: 'organizations',
    },
  }),

  // source definition groups
  listLayerDefGroups: makeUrl({
    url: `${adminRoot}/layer-definition-group/list`,
    defaultQuery: DEFAULT_PAGE_QUERY,
  }),
  editLayerDefGroup: makeUrl({
    url: `${adminRoot}/layer-definition-group/:mapLayerDefGroupId`,
    defaultQuery: DEFAULT_PAGE_QUERY,
  }),
  newLayerDefGroup: makeUrl({ url: `${adminRoot}/layer-definition-group/new` }),

  listSourceDefs: makeUrl({
    url: `${adminRoot}/source-definition/list`,
    defaultQuery: DEFAULT_PAGE_QUERY,
  }),
  editSourceDef: makeUrl<{ mapSourceDefId: string }>({
    url: `${adminRoot}/source-definition/:mapSourceDefId`,
  }),
  newSourceDef: makeUrl({ url: `${adminRoot}/source-definition/new` }),

  // layer definitions
  editLayerDef: makeUrl<{ mapLayerDefId: string }>({
    url: `${adminRoot}/layer-definition/:mapLayerDefId`,
  }),
  newLayerDef: makeUrl({ url: `${adminRoot}/layer-definition/new` }),
  newLayerDefFromSourceDef: makeUrl<{ mapSourceDefId: string }>({
    url: `${adminRoot}/source-definition/:mapSourceDefId/new-layer`,
  }),

  // packages
  newPackage: makeUrl({ url: `${adminRoot}/package/new` }),
  editPackage: makeUrl<{ packageId: string }>({
    url: `${adminRoot}/package/:packageId`,
  }),
  listPackages: makeUrl({
    url: `${adminRoot}/package/list`,
    defaultQuery: DEFAULT_PAGE_QUERY,
  }),

  // Orders
  newOrder: makeUrl({ url: `${adminRoot}/order/new` }),
  editOrder: makeUrl<{ orderId: string }>({
    url: `${adminRoot}/order/:orderId`,
  }),
  listOrders: makeUrl({
    url: `${adminRoot}/order/list`,
    defaultQuery: DEFAULT_PAGE_QUERY,
  }),

  // TargetDeliveries
  newTargetDelivery: makeUrl<{ orderId: string }>({
    url: `${adminRoot}/order/:orderId/new-target-delivery`,
  }),
  editTargetDelivery: makeUrl<{ orderId: string; targetDeliveryDate: string }>({
    url: `${adminRoot}/target-delivery/:orderId/:targetDeliveryDate`,
  }),

  // flights
  editFlight: makeUrl<{ deliveryId: string; flightDate: string }>({
    url: `${adminRoot}/flight/:deliveryId/:flightDate`,
  }),
  newFlightFromDelivery: makeUrl<{ deliveryId: string }>({
    url: `${adminRoot}/delivery/:deliveryId/new-flight`,
  }),

  // deliveries
  newDelivery: makeUrl<{ orderId: string; targetDeliveryDate: string }>({
    url: `${adminRoot}/target-delivery/:orderId/:targetDeliveryDate/new-delivery`,
  }),
  editDelivery: makeUrl<{ deliveryId: string }>({
    url: `${adminRoot}/delivery/:deliveryId`,
  }),
  deliveryProcGroup: makeUrl<{
    tab?: string
    deliveryId: string
    procGroup: string
  }>({
    url: `${adminRoot}/delivery/:deliveryId/proc-group/:procGroup/:tab`,
    defaultParams: {
      tab: 'kato',
    },
  }),

  // kato
  yourQueue: makeUrl<{ userId: string }>({
    url: `${adminRoot}/your-queue/:userId`,
  }),
  myQueue: makeUrl<{ userId: string }>({
    url: `${adminRoot}/my-queue`,
  }),

  // status
  deliveryStatus: makeUrl({
    url: `${adminRoot}/status/delivery`,
    defaultQuery: DEFAULT_PAGE_QUERY,
  }),
  deliveryGroupFileStatus: makeUrl<
    {},
    { orderBy?: string; filter?: string } & typeof DEFAULT_PAGE_QUERY
  >({
    url: `${adminRoot}/status/delivery-group-file`,
    defaultQuery: DEFAULT_PAGE_QUERY,
  }),
  jobStatus: makeUrl<{
    jobId: number
  }>({
    url: `${adminRoot}/status/job-status/:jobId`,
  }),
  migrationStatus: makeUrl<{ organizationId: string }>({
    url: `${adminRoot}/status/migration/:organizationId`,
    defaultParams: {
      organizationId: 'all',
    },
    defaultQuery: DEFAULT_PAGE_QUERY,
  }),

  monitorEvent: makeUrl<{ eventId: string }>({
    url: '/monitor/event/:eventId',
  }),
  monitorEventList: makeUrl({
    url: '/monitor/events',
    defaultQuery: { ...DEFAULT_PAGE_QUERY, sort: 'asc', q: '' },
  }),

  // maestro
  pipeline: makeUrl<{ pipelineId: string }>({
    url: `${adminRoot}/maestro/pipelines/:pipelineId`,
  }),
  pipelines: makeUrl({
    url: `${adminRoot}/maestro/pipelines`,
    defaultQuery: DEFAULT_PAGE_QUERY,
  }),
  job: makeUrl({
    url: `${adminRoot}/maestro/jobs/:jobId`,
  }),
  jobs: makeUrl({
    url: `${adminRoot}/maestro/jobs`,
  }),

  // kato for pipeline
  katoQueueForPipeline: makeUrl<{ pipelineId: string }>({
    url: `${adminRoot}/kato/:pipelineId/queue`,
  }),

  katoInProgressForPipeline: makeUrl<{ pipelineId: string }>({
    url: `${adminRoot}/kato/:pipelineId/in-progress`,
  }),
  katoCompletedForPipeline: makeUrl<{ pipelineId: string }>({
    url: `${adminRoot}/kato/:pipelineId/completed`,
  }),
  katoJobForPipeline: makeUrl<{ pipelineId: string }>({
    url: `${adminRoot}/kato/:pipelineId/job/:jobId`,
  }),

  // sample plan
  samplePlans: makeUrl({
    url: '/sample-plans',
  }),
  samplePlan: makeUrl<{ samplePlanId: string }>({
    url: '/sample-plan/:samplePlanId',
  }),
  samplePlanResults: makeUrl<{ samplePlanId: string }>({
    url: '/sample-plan/results/:samplePlanId',
  }),

  // admin processing queue
  processingQueue: makeUrl({
    url: '/processing-queue',
  }),

  //coment
  viewComment: makeUrl({
    url: '/view-comment/:commentId',
  }),
}

export type RouteParams = Record<string, string | undefined>
export type SearchParams = Record<string, string | undefined>

const selectSearchParams = (state: RootStore) => state.router.searchParams

export const selectPagingInfo = createSelector(
  [selectSearchParams],
  (searchParams) => {
    const page = Number(searchParams.page ?? 0)
    const pageSize = Number(searchParams.pageSize || DEFAULT_PAGE_SIZE)

    return {
      page,
      pageSize,
    }
  }
)

type UrlParams = Record<string, string | number | undefined>
type UrlQuery = Record<string, string | number | boolean | null | undefined>

export interface UrlInfo<P extends UrlParams = {}, Q extends UrlQuery = {}> {
  url: string
  defaultParams?: Partial<P>
  defaultQuery?: Partial<Q>
}

export function url<P extends UrlParams, Q extends UrlQuery>(
  info: UrlInfo<P, Q>,
  params: Partial<P> = {},
  query: Partial<Q> = {}
) {
  const { url: urlTemplate, defaultParams = {}, defaultQuery = {} } = info
  const urlParts = urlTemplate.split('/')
  const _params = {
    ...(defaultParams as any),
    ...(params as any),
  }

  let uri = urlParts
    .map((part) => {
      if (part.startsWith(':')) {
        const key = part.slice(1)
        const value = _params[key]

        if (value) {
          return value
        }
      }

      return part
    })
    .join('/')

  if (uri.includes(':')) {
    throw new Error(`not all url parameters are filled: ${uri}`)
  }

  const _query = {
    ...(defaultQuery as any),
    ...(query as any),
  }

  const queryString = new URLSearchParams(
    Object.entries(_query)
      .filter(([, value]) => value && value !== '')
      .reduce((obj, [key, value]) => {
        obj[key] = value

        return obj
      }, {})
  ).toString()

  if (queryString) {
    uri = `${uri}?${queryString}`
  }

  return uri
}

export function urlMatch(info: UrlInfo, test: string) {
  const urlParts = info.url.split('/')
  const testParts = test.split('/')
  const len = Math.max(urlParts.length, testParts.length)

  for (let i = 0; i < len; i += 1) {
    if ((urlParts[i] ?? '').startsWith(':')) {
      continue
    }
    if (urlParts[i] !== testParts[i]) {
      return false
    }
  }

  return true
}

export const isChildUrl = (info: UrlInfo, test: string) => {
  const urlParts = info.url.split('/')
  const testParts = test.split('/')
  const len = urlParts.length
  let i = 0
  for (; i < len; i += 1) {
    if ((urlParts[i] ?? '').startsWith(':')) {
      continue
    }
    if (urlParts[i] !== testParts[i]) {
      return ''
    }
  }

  return testParts.slice(i).join('/')
}
