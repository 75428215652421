import bbox from '@turf/bbox'
import { BBox } from '@turf/helpers'

export interface GeometryObject {
  geometry: GeoJSON.Polygon | GeoJSON.MultiPolygon
}

const BOUNDS_BUFFER = 0.0001

const getBoundsFromParcels = (parcels: GeometryObject[]) => {
  if (parcels.length === 0) {
    return
  }

  let bounds: BBox = [
    Number.MAX_VALUE,
    Number.MAX_VALUE,
    -Number.MAX_VALUE,
    -Number.MAX_VALUE,
  ]

  parcels.forEach((parcel) => {
    const parcelBounds = bbox(parcel.geometry)
    bounds = [
      Math.min(parcelBounds[0], bounds[0]) - BOUNDS_BUFFER,
      Math.min(parcelBounds[1], bounds[1]) - BOUNDS_BUFFER,
      Math.max(parcelBounds[2], bounds[2]) + BOUNDS_BUFFER,
      Math.max(parcelBounds[3], bounds[3]) + BOUNDS_BUFFER,
    ]
  })

  return bounds
}

export default getBoundsFromParcels
