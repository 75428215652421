import * as React from 'react'
import { PureComponent } from 'react'
import { Checkbox, FormControlLabel } from '@mui/material'
import i18n, { keys } from '../../i18n'
import { connect } from '../../redux/connect'
import { updatePreferences } from '../../redux/preferences/redux'
import { AppDispatchProps, RootStore } from '../../redux/types'

class LayerDrawerNotesToggle extends PureComponent<
  ReduxProps & AppDispatchProps
> {
  render() {
    return (
      <FormControlLabel
        label={i18n.t(keys.notes.showOnMap)}
        control={
          <Checkbox onChange={this.toggle} checked={this.props.showNotes} />
        }
      />
    )
  }

  toggle = () => {
    this.props.dispatch(updatePreferences({ showNotes: !this.props.showNotes }))
  }
}

const mapState = (state: RootStore) => ({
  showNotes: state.preferences.showNotes,
})
type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps, {}, AppDispatchProps>(mapState)(
  LayerDrawerNotesToggle
)
