import {
  ClassStats,
  createClassStatsCalculator,
  createValueStatsCalculator,
} from './StatsCalculator'

export interface Stats {
  min?: number
  max?: number
  mean?: number
  size: number
  stdev?: number
  cv?: number
}

export interface CategoryStats {
  data: {
    stats: Stats
    stops: {
      [stopIndex: number]: Stats
    }
    minStopSize: number
  }
  noData: Stats
  coverage: Stats
}

function getStopIndex(
  data: any,
  stops: (string | number)[],
  type: 'value' | 'class'
) {
  if (type === 'value') {
    // tslint:disable-next-line: no-increment-decrement
    for (let i = stops.length - 1; i >= 0; i--) {
      if (data.value >= stops[i]) {
        return i
      }
    }

    return 0
  }

  return stops.findIndex((val) => String(val) === String(data.value))
}

export function calcStats(
  reduced: any,
  stops: (string | number)[]
): CategoryStats {
  const type = reduced.data[0] && 'min' in reduced.data[0] ? 'value' : 'class'

  const createCalculator =
    type === 'value' ? createValueStatsCalculator : createClassStatsCalculator

  const dataCalc = createCalculator()
  dataCalc.update(reduced.data)
  const dataStats = dataCalc.digest()

  const noDataCalc = createCalculator()
  noDataCalc.update(reduced.noData)
  const noData = noDataCalc.digest()

  const coverageCalc = createCalculator()
  coverageCalc.update(reduced.coverage)
  const coverage = coverageCalc.digest()

  const dataStopCalcs: Record<number, ReturnType<typeof createCalculator>> = {}
  stops.forEach((_, index) => {
    dataStopCalcs[index] = createCalculator()
  })

  for (const datum of reduced.data) {
    const index = getStopIndex(datum, stops, type)

    const dataStopCalc = (dataStopCalcs[index] =
      dataStopCalcs[index] || createCalculator())

    dataStopCalc.update([datum])
  }

  const dataStops: Record<number, ClassStats> = {}
  let minStopSize = Infinity
  for (const [stopIndex, calculator] of Object.entries(dataStopCalcs)) {
    const stopStats = calculator.digest()
    dataStops[stopIndex] = stopStats
    if (stopStats.size !== 0 && stopStats.size < minStopSize) {
      minStopSize = stopStats.size
    }
  }

  const data = {
    minStopSize,
    stats: dataStats,
    stops: dataStops,
  }

  return {
    data,
    noData,
    coverage,
  }
}
