import * as React from 'react'
import FitSelectedNote from '../map/controls/FitSelectedNote'
import { NoteForm } from './NoteForm'
import { useRedux } from '../hooks/useRedux'
import {
  refreshListNotes,
  refreshListProjects,
  selectEditingNote,
} from './selectors'
import { NoteGeometryDialog } from './NoteGeometryDialog'
import { actions } from './redux'
import { Note, OrganizationFeature } from '../vvapi/models'
import { client, gql } from '../graphql/client'
import { useMap } from '../map/withMap'
import { selectOrganization } from '../data/selectOrganization'
import getContainingParcelId from './getContainingParcelId'
import { selectDeliveryParcelsByParcelId } from '../data/selectDelivery'
import warnConfirm from '../admin/warnConfirm'
import i18n, { keys } from '../i18n'
import { showFeedback } from '../redux/notifications/redux'

export const NoteMapComponents = () => {
  const [state, dispatch] = useRedux()

  const { map } = useMap()

  const organization = selectOrganization(state)

  const parcelsById = selectDeliveryParcelsByParcelId(state)

  const [pendingNote, setPendingNote] = React.useState<
    Pick<Note, 'content' | 'templateId' | 'feature' | 'projectId'> | undefined
  >()

  const [noteGeometry, setNoteGeometry] = React.useState<
    OrganizationFeature | undefined
  >()

  const [editingNoteContent, setEditingNoteContent] = React.useState<
    Note['content'] | undefined
  >()

  const { isCreatingNote, noteEditMode } = state.notes

  const editingNote = selectEditingNote(state)

  const getMapCenter = () => {
    if (!map) {
      return
    }

    return map?.getCenter()
  }

  const handleNewNoteCancel = () => {
    dispatch(actions.setIsCreatingNote(false))
    setPendingNote(undefined)
    setNoteGeometry(undefined)
  }

  const handleNewNoteCreate = (
    newNote: Pick<Note, 'content' | 'templateId' | 'projectId'>
  ) => {
    const latLng = getMapCenter()
    if (!latLng) {
      return
    }

    const feature = {
      geometry: {
        type: 'Point',
        coordinates: [latLng.lng, latLng.lat],
      },
    } as OrganizationFeature

    setPendingNote({ ...newNote, feature })
    dispatch(actions.setNoteEditMode('geometry'))
  }

  const handlEditNoteCancel = () => {
    dispatch(actions.setEditingNoteId(undefined))
  }

  const handlEditNote = async (note: Pick<Note, 'content' | 'projectId'>) => {
    try {
      if (!editingNote) {
        return
      }

      if (noteGeometry) {
        const { id, ...feature } = noteGeometry
        const containingParcelId = getContainingParcelId(
          feature.geometry!,
          parcelsById
        )

        await client.request({
          query: gql`
            mutation EDIT_NOTE_AND_GEOMETRY(
              $noteId: uuid!
              $featureId: uuid!
              $note: Note_set_input!
              $feature: Feature_set_input!
            ) {
              update_Note_by_pk(pk_columns: { id: $noteId }, _set: $note) {
                id
              }
              update_Feature_by_pk(
                pk_columns: { id: $featureId }
                _set: $feature
              ) {
                id
              }
            }
          `,
          variables: {
            noteId: editingNote.id,
            note: {
              content: note.content,
              parcelId: containingParcelId,
              projectId: note.projectId,
            },
            featureId: editingNote.feature.id,
            feature: feature,
          },
        })
      } else {
        await client.request({
          query: gql`
            mutation EDIT_NOTE($noteId: uuid!, $note: Note_set_input!) {
              update_Note_by_pk(pk_columns: { id: $noteId }, _set: $note) {
                id
              }
            }
          `,
          variables: {
            noteId: editingNote.id,
            note: {
              content: note.content,
              projectId: note.projectId ? note.projectId : null,
            },
          },
        })
      }

      setNoteGeometry(undefined)
      refreshListNotes()
      refreshListProjects()
      dispatch(actions.setEditingNoteId(undefined))
    } catch (e) {
      console.error(e)
      dispatch(
        showFeedback({
          message: i18n.t(keys.notes.failedToSave),
          severity: 'error',
        })
      )
    }
  }

  const handleSaveNote = async () => {
    try {
      if (!isCreatingNote || !pendingNote) {
        return
      }

      const { feature, ...note } = pendingNote

      const containingParcelId = getContainingParcelId(
        noteGeometry?.geometry!,
        parcelsById
      )

      await client.request({
        query: gql`
          mutation INSERT_NOTE($note: Note_insert_input!) {
            insert_Note_one(object: $note) {
              id
            }
          }
        `,
        variables: {
          note: {
            ...note,
            parcelId: containingParcelId,
            organizationId: organization?.id,
            Feature: { data: noteGeometry },
          },
        },
      })
      if (!!state.notes.createAnotherTemplateId) {
        dispatch(actions.setNoteEditMode('content'))
      } else {
        dispatch(actions.setIsCreatingNote(false))
      }

      setNoteGeometry(undefined)
      refreshListNotes()
      refreshListProjects()
    } catch (e) {
      console.error(e)
      dispatch(
        showFeedback({
          message: i18n.t(keys.notes.failedToSave),
          severity: 'error',
        })
      )
    }
  }

  const handlEditNoteGeometryCancel = () => {
    setNoteGeometry(undefined)
    dispatch(actions.setNoteEditMode('content'))
  }

  const handleEditNoteGeometryComplete = async () => {
    try {
      if (!editingNote) {
        return
      }

      if (noteGeometry && editingNoteContent) {
        const { id, ...feature } = noteGeometry

        const containingParcelId = getContainingParcelId(
          feature.geometry!,
          parcelsById
        )

        await client.request({
          query: gql`
            mutation EDIT_NOTE_AND_GEOMETRY(
              $noteId: uuid!
              $featureId: uuid!
              $note: Note_set_input!
              $feature: Feature_set_input!
            ) {
              update_Note_by_pk(pk_columns: { id: $noteId }, _set: $note) {
                id
              }
              update_Feature_by_pk(
                pk_columns: { id: $featureId }
                _set: $feature
              ) {
                id
              }
            }
          `,
          variables: {
            noteId: editingNote.id,
            note: { content: editingNoteContent, parcelId: containingParcelId },
            featureId: editingNote.feature.id,
            feature: feature,
          },
        })
      } else if (noteGeometry) {
        const { id, ...feature } = noteGeometry

        const containingParcelId = getContainingParcelId(
          feature.geometry!,
          parcelsById
        )

        await client.request({
          query: gql`
            mutation EDIT_NOTE_AND_GEOMETRY(
              $noteId: uuid!
              $featureId: uuid!
              $note: Note_set_input!
              $feature: Feature_set_input!
            ) {
              update_Note_by_pk(pk_columns: { id: $noteId }, _set: $note) {
                id
              }
              update_Feature_by_pk(
                pk_columns: { id: $featureId }
                _set: $feature
              ) {
                id
              }
            }
          `,
          variables: {
            noteId: editingNote.id,
            note: { parcelId: containingParcelId },
            featureId: editingNote.feature.id,
            feature: feature,
          },
        })
      } else if (editingNoteContent) {
        await client.request({
          query: gql`
            mutation EDIT_NOTE($noteId: uuid!, $note: Note_set_input!) {
              update_Note_by_pk(pk_columns: { id: $noteId }, _set: $note) {
                id
              }
            }
          `,
          variables: {
            noteId: editingNote.id,
            note: { content: editingNoteContent },
          },
        })
      }

      setNoteGeometry(undefined)
      dispatch(actions.setEditingNoteId(undefined))
      refreshListNotes()
      refreshListProjects()
    } catch (e) {
      console.error(e)
      dispatch(
        showFeedback({
          message: i18n.t(keys.notes.failedToSave),
          severity: 'error',
        })
      )
    }
  }

  const handleEditNoteGeometry = (note: Pick<Note, 'content'>) => {
    setEditingNoteContent(note.content)
    dispatch(actions.setNoteEditMode('geometry'))
  }

  const handleDeleteNote = async () => {
    try {
      if (!editingNote) {
        return
      }

      if (
        await warnConfirm({
          title: i18n.t(keys.notes.notesPopup.deleteConfirmTitle),
          message: i18n.t(keys.notes.notesPopup.deleteConfirmMessage),
          action: i18n.t(keys.generic.confirm),
        })
      ) {
        await client.request({
          query: gql`
            mutation SOFT_DELETE_NOTE($id: uuid!) {
              update_Note_by_pk(
                pk_columns: { id: $id }
                _set: { deletedAt: "now()" }
              ) {
                id
              }
            }
          `,
          variables: { id: editingNote.id },
        })
      }

      setNoteGeometry(undefined)
      dispatch(actions.setEditingNoteId(undefined))
      refreshListNotes()
      refreshListProjects()
    } catch (e) {
      console.error(e)
      dispatch(
        showFeedback({
          message: i18n.t(keys.notes.failedToSave),
          severity: 'error',
        })
      )
    }
  }

  return (
    <>
      <FitSelectedNote />

      <NoteForm
        key="notes_add"
        onCreateNote={handleNewNoteCreate}
        onCancel={handleNewNoteCancel}
        open={noteEditMode === 'content' && isCreatingNote}
      />

      <NoteForm
        key="notes_edit"
        onEditNote={handlEditNote}
        onCancel={handlEditNoteCancel}
        onEditLocation={handleEditNoteGeometry}
        open={noteEditMode === 'content' && !!editingNote}
        initialNote={editingNote}
        onDelete={handleDeleteNote}
        mode="edit"
      />

      <NoteGeometryDialog
        open={noteEditMode === 'geometry' && !!editingNote}
        note={editingNote}
        onNoteChange={setNoteGeometry}
        onCancel={handlEditNoteGeometryCancel}
        onSave={handleEditNoteGeometryComplete}
        mode="edit"
      />

      <NoteGeometryDialog
        open={noteEditMode === 'geometry' && isCreatingNote}
        onNoteChange={setNoteGeometry}
        onCancel={handleNewNoteCancel}
        note={pendingNote}
        onSave={() => handleSaveNote()}
      />
    </>
  )
}
