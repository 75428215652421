import { createSelector } from 'reselect'

import { MapboxGL } from '../../map/MapboxGL'
import mapboxSatelliteJson from '../../map/styles/mapbox-satellite.json'
import { getElevationLayer } from '../ElevationLayer/selectors'
import { getSoilLayers } from '../SoilLayer/selectors'
import { getFieldsLayer } from './FieldsLayerSelector'
import { getNotesLayer } from './NotesLayerSelector'
import { selectedProductLayers } from './SelectedProductLayerSelector'
import { selectSelectedPolygonLayer } from './SelectSelectedPolygonLayer'
import { selectStatsMask } from './selectStatsMask'
import { getUserPositionLayer } from './UserPositionLayerSelector'

/**
 * it merges all of our mapbox style json:
 * default, products, fields, etc
 */
export const selectAllMapLayers = createSelector(
  selectedProductLayers,
  getFieldsLayer,
  getUserPositionLayer,
  getNotesLayer,
  getElevationLayer,
  getSoilLayers,
  selectSelectedPolygonLayer,
  selectStatsMask,
  (
    productsLayers,
    fields,
    userPosition,
    notes,
    elevationLayer,
    soilLayers,
    selectedPolygonLayers,
    statsMask,
  ): MapboxGL.Style => ({
    ...mapboxSatelliteJson,
    sources: {
      ...mapboxSatelliteJson.sources,
      ...productsLayers.sources,
      ...fields.sources,
      ...userPosition.sources,
      ...notes.sources,
      ...elevationLayer.sources,
      ...soilLayers.sources,
      ...selectedPolygonLayers.sources,
      ...statsMask.sources
    },
    layers: [
      ...mapboxSatelliteJson.layers,
      ...productsLayers.layers,
      ...fields.layers,
      ...userPosition.layers,
      ...notes.layers,
      ...elevationLayer.layers,
      ...soilLayers.layers,
      ...selectedPolygonLayers.layers,
      ...statsMask.layers
    ],
  })
)
