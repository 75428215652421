import { MapLayerData, MapLayerDefData, MapSourceData } from '../data/types'
import { SourceTypes } from '../graphql/types'
import { parcelsToGeoJSON } from '../postgis/selectors/FieldsLayerSelector'
import * as bbox from '../util/bbox'
import metersToPixelsAtMaxZoom from '../util/metersToPixelsAtMaxZoom'
import { getApiUrl } from '../vvapi/apiResource/createApiResource'
import { makeFilters } from './filters'
import { MapboxGL } from './MapboxGL'
import { dotSizeMap, makePaint, MakePaintProps } from './paint'

// get all source types
type MapBoxSources =
  | MapboxGL.VectorSource
  | MapboxGL.RasterSource
  | MapboxGL.GeoJSONSource
  | MapboxGL.ImageSource
  | MapboxGL.VideoSource
  | MapboxGL.GeoJSONSourceRaw

// rewrite bounds and type
interface AlteredMapLayer {
  id: string
  bounds: number[]
  type: MapBoxSources['type']
  minzoom: number
  maxzoom: number
  scheme: string
  tileSize: number
  attribution: string
  tiles: string[]
}

// concatenate MapBoxSources['type']; join MapLayerData fields
type Source = Omit<MapBoxSources, 'type'> & AlteredMapLayer

export const makeTileSource = (source: MapSourceData): Source => ({
  id: `${source.id}-source`,
  bounds: bbox.parse(source.bounds),
  minzoom: Number(source.minzoom ?? 0),
  maxzoom: Number(source.maxzoom ?? 16),
  scheme: source.scheme ?? 'xyz',
  tileSize: source.tileSize ?? 512,
  attribution: source.attribution ?? '&copy; VineView',
  tiles: addTileUrl(source.tiles),
  type: (source.type ?? 'vector') as Source['type'],
})

export const addTileUrl = (tiles: string[] | undefined | null) => {
  const tileUrl = `${getApiUrl()}/api/v3/map-source/`

  return (tiles ?? []).map(
    (tile) => `${tileUrl}${tile}`
  )
}

export const makeBlockByBlockSource = (source: MapSourceData, layerDef: MapLayerDefData, mean?: number) => {

  const geoJson = parcelsToGeoJSON([source.parcel], undefined, false, { [layerDef.dataProperty.property]: mean })

  return {
    data: geoJson,
    type: 'geojson',
    attribution: source.attribution ?? '&copy; VineView',
  }

}

type MakeTileLayersOptions = MakePaintProps

export type TileLayer = ReturnType<typeof makeTileLayers>

export const makeTileLayers = (options: MakeTileLayersOptions) => {
  const paint = makePaint(options)
  const filters = makeFilters(options)
  const { layer } = options
  const sourceType = layer.mapLayerDef.mapSourceDef.type

  if (sourceType === 'raster' || sourceType === 'raster-background') {
    return makeRasterLayer(layer, paint)
  }

  const sourceToType: Partial<Record<SourceTypes, MapboxGL.Layer['type']>> = {
    line: 'line',
    point: 'circle',
    zone: 'fill',
  }

  const type = sourceToType[sourceType]

  if (type) {
    return makeVectorLayer(layer, type, paint, ['all', ...filters], options.settings.blockByBlockAverage)
  }

  return
}

const makeRasterLayer = (
  layer: MapLayerData,
  paint: MapboxGL.RasterPaint = {}
): MapboxGL.Layer => ({
  paint: { 'raster-resampling': 'nearest', ...paint } as any,
  id: `${layer.id}-layer`,
  type: 'raster',
  source: `${layer.mapSourceId}-source`,
  'source-layer': layer.mapSourceId,
  minzoom: 0,
  maxzoom: 24,
})

const makeVectorLayer = (
  layer: MapLayerData,
  type: MapboxGL.Layer['type'],
  paint: MapboxGL.CirclePaint | MapboxGL.LinePaint | MapboxGL.FillPaint = {},
  filter: MapboxGL.Layer['filter'],
  blockByBlockAverage = false,
): MapboxGL.Layer => {

  if (blockByBlockAverage) {
    return {
      filter,
      paint,
      type: 'fill',
      id: `${layer.id}-block-by-block-layer`,
      source: `${layer.mapSourceId}-block-by-block`
    }
  }

  return {
    type,
    paint,
    filter,
    id: `${layer.id}-layer`,
    source: `${layer.mapSourceId}-source`,
    'source-layer': layer.mapSourceId,
  }
}

export const makeBorderLayer = (
  options: MakeTileLayersOptions
): MapboxGL.Layer => {
  const { layer, settings } = options
  const bounds = bbox.parse(layer.mapSource.bounds)
  const latitude = bounds[1]
  const filters = makeFilters(options)

  return {
    type: 'circle',
    paint: {
      'circle-radius': [
        'interpolate',
        ['exponential', 2],
        ['zoom'],
        ...[0, 0],
        ...[13, 2 * dotSizeMap[settings.dotSize ?? 'medium']],
        ...[14, 2 * dotSizeMap[settings.dotSize ?? 'medium']],
        ...[15, 2 * dotSizeMap[settings.dotSize ?? 'medium']],
        ...[16, 3 * dotSizeMap[settings.dotSize ?? 'medium']],
        ...[17, 2 * dotSizeMap[settings.dotSize ?? 'medium']],
        ...[
          25,
          metersToPixelsAtMaxZoom(0.45, latitude) *
          dotSizeMap[settings.dotSize ?? 'medium'],
        ],
      ],
      'circle-color': [
        'case',
        [
          '!=',
          ['number', ['get', layer.mapLayerDef.noDataProperty.property], 0],
          ['number', layer.mapLayerDef.noDataProperty.noDataValue, -1],
        ],
        options.color?.coverageColor ?? 'transparent',
        options.color?.noDataColor ?? 'transparent',
      ],
      'circle-stroke-width': {
        stops: [
          [0, 0],
          [17, 0],
          [18, 0.2],
          [19, 0.3],
          [20, 0.4],
          [21, 0.6],
          [22, 1],
          [23, 1],
          [24, 1],
          [25, 1],
        ],
      },
      'circle-stroke-color': 'black',
    },
    filter: ['all', ...filters],
    id: `${layer.id}-border-layer`,
    source: `${layer.mapSourceId}-source`,
    'source-layer': layer.mapSourceId,
  }
}
