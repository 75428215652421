import * as React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
  styled,
  useTheme,
} from '@mui/material'
import i18n, { keys } from '../../i18n'

interface Props {
  open: boolean
  onCancel?: () => void
  onConfirm: () => void
  title: string | React.ReactNode
  message: string | React.ReactNode
  dangerMode?: boolean
}

const Title = styled(Typography)`
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0.15px;
  text-align: left;
`

const Description = styled(Typography)`
  font-size: 16px;
  font-weight: 600;
  line-height: 19.2px;
  letter-spacing: -0.035em;
  text-align: left;
`

export const ConfirmationModal = ({
  open,
  onCancel,
  onConfirm,
  title,
  message,
  dangerMode,
}: Props) => {
  const theme = useTheme()
  return (
    <Dialog open={open}>
      <DialogTitle>
        {typeof title === 'string' ? <Title>{title}</Title> : title}
      </DialogTitle>
      <Divider />
      <DialogContent>
        {typeof message === 'string' ? (
          <Description>{message}</Description>
        ) : (
          message
        )}
      </DialogContent>
      <DialogActions>
        <Button
          style={{ color: theme.palette.text.primary }}
          onClick={onCancel}
        >
          {i18n.t(keys.generic.cancel)}
        </Button>
        <Button color={dangerMode ? 'error' : 'primary'} onClick={onConfirm}>
          {dangerMode
            ? i18n.t(keys.generic.delete)
            : i18n.t(keys.generic.confirm)}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
