import * as React from 'react'

import centroid from '@turf/centroid'
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from '@mui/material'

import { NoteWithTemplate, OrgFeatureGeometry, User } from '../../vvapi/models'
import { useRedux } from '../../hooks/useRedux'
import {
  selectFilteredNotes,
  selectNotesById,
  selectNotesByProjectId,
  selectProjectsById,
} from '../../notes/selectors'
import { downloadCSV, downloadXLSX } from '../../util/table'
import areaUserString from '../../notes/areaUserString'
import i18n, { keys } from '../../i18n'
import ItemSelectionForm from '../../UI/ItemSelection/ItemSelectionForm'
import { Project } from '../../graphql/types'

interface Props {
  open: boolean
  onClose: () => void
}

type ExportFileType = 'csv' | 'xlsx'

export const ExportProjectsDialog = ({ open, onClose }: Props) => {
  const [state] = useRedux()

  const selectedProjectIds = state.notes.selectedProjectIds
  const projectsById = selectProjectsById(state)
  const selectedProjects = React.useMemo(() => {
    return selectedProjectIds.reduce((acc, id) => {
      const project = projectsById[id]
      if (project) {
        acc.push(project)
      }
      return acc
    }, [] as Project[])
  }, [selectedProjectIds, projectsById])
  const notesById = selectNotesById(state)
  const notesByProjectId = selectNotesByProjectId(state)
  const filteredNoteIds = selectFilteredNotes(state)
  const isFiltered = React.useMemo(() => {
    const { searchTerm, fromDate, toDate, formType, projectType } =
      state.notes.filterInfo
    return !!searchTerm || !!formType || !!fromDate || !!toDate || !!projectType
  }, [state.notes.filterInfo])
  const filteredProjects = React.useMemo(() => {
    if (!isFiltered) {
      return Object.values(projectsById)
    }
    const projectIds = new Set(
      filteredNoteIds.map((id) => notesById[id].projectId ?? 'general')
    )
    const projs = Object.values(projectsById).filter((project) =>
      projectIds.has(project.id)
    )
    return projs
  }, [isFiltered, filteredNoteIds, projectsById, notesById])
  const preferredUnitSystem = state.preferences.preferredUnitSystem

  const [fileType, setFileType] = React.useState<ExportFileType>('csv')
  const [exportFileName, setExportFileName] = React.useState('Notes')
  const handleExportCSV = (selectedNotes: string[]) => {
    const data = makeTableData(selectedNotes)
    downloadCSV({ data, filename: exportFileName })
    onClose()
  }

  const handleExportXLSX = (selectedNotes: string[]) => {
    const data = makeTableData(selectedNotes)
    downloadXLSX({ data, filename: exportFileName })
    onClose()
  }

  const makeTableData = (selectedNotes: string[]) => {
    const data = selectedNotes.map((id) => makeTableRow(notesById[id]))

    return data
  }

  const makeTableRow = (note: NoteWithTemplate) => {
    const {
      createdBy,
      updatedBy,
      feature: { geometry },
      content,
    } = note

    const centerPoint = centroid(geometry as any)
    const lng = centerPoint.geometry!.coordinates[0]
    const lat = centerPoint.geometry!.coordinates[1]

    let areaString = ''
    if (geometry.type === 'Polygon') {
      areaString = areaUserString(geometry, preferredUnitSystem)
    }

    return {
      Template: i18n.t(`noteForm.reservedNames.${note.templateName}`, {
        defaultValue: note.templateName,
      }),
      Project: note.projectId
        ? projectsById[note.projectId].name
        : i18n.t(keys.noteForm.reservedNames.__VV__GENERAL__TEMPLATE__),
      'Geometry Type': geometry.type,
      Area: areaString,
      Latitude: lat.toString(),
      Longitude: lng.toString(),
      Coordinates: JSON.stringify(getCoordinates(geometry)),
      'Created By': getName(createdBy),
      'Created At': note.createdAt,
      'Updated By': getName(updatedBy),
      'Updated At': note.updatedAt,
      ...mapContent(content),
    }
  }

  const mapContent = (content: NoteWithTemplate['content']) => {
    const mappedContent = {}
    Object.entries(content).forEach(([key, value]) => {
      if (value.type === 'checkbox') {
        mappedContent[key] = Boolean(value.value)
      } else {
        mappedContent[key] = value.value
      }
    })

    return mappedContent
  }
  const getName = (user?: User) => {
    return user && `${user.firstName} ${user.lastName}`
  }

  const getCoordinates = (geometry: OrgFeatureGeometry) => {
    let coordinates
    if (geometry.type === 'Polygon') {
      coordinates = geometry.coordinates[0]
    } else {
      coordinates = geometry.coordinates
    }

    return coordinates
  }

  const onCommit = (selectedProjects: Project[]) => {
    const filteredNotesSet = new Set(filteredNoteIds)
    const selectedNotes: string[] = []
    for (const project of selectedProjects) {
      selectedNotes.push(
        ...Object.keys(notesByProjectId[project.id]).filter((noteId) =>
          filteredNotesSet.has(noteId)
        )
      )
    }

    if (fileType === 'csv') {
      handleExportCSV(selectedNotes)
    } else {
      handleExportXLSX(selectedNotes)
    }
  }

  return (
    <ItemSelectionForm
      open={open}
      onClose={onClose}
      onCommit={onCommit}
      title={i18n.t(keys.notes.exportBoxTitle)}
      sectionTitle={i18n.t(keys.projectsToExport)}
      items={filteredProjects}
      defaultSelectedItems={selectedProjects}
      additionalSections={{
        [i18n.t(keys.filename)]: (
          <Stack>
            <TextField
              fullWidth
              autoFocus
              value={exportFileName}
              onChange={(event) => setExportFileName(event.target.value)}
            />
          </Stack>
        ),
        [i18n.t(keys.generic.downloadFormat)]: (
          <FormControl>
            <RadioGroup
              value={fileType}
              onChange={(e) => setFileType(e.target.value as ExportFileType)}
              name="radio-buttons-group"
            >
              <FormControlLabel
                value="xlsx"
                control={<Radio />}
                label={i18n.t(keys.generic.xlsx)}
              />
              <FormControlLabel
                value="csv"
                control={<Radio />}
                label={i18n.t(keys.generic.csv)}
              />
            </RadioGroup>
          </FormControl>
        ),
      }}
    />
  )
}
