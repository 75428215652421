import { Stack, Typography, styled } from '@mui/material'
import * as React from 'react'
import i18n, { keys } from '../../i18n'

interface Props {
  projectNoteCount: number
  projectName: string
}

const DeleteMessageText = styled(Typography)`
  font-size: 16px;
  font-weight: 600;
  line-height: 19.2px;
  letter-spacing: -0.035em;
  text-align: left;
`

export const DeleteProjectForm = ({ projectNoteCount, projectName }: Props) => {
  return (
    <Stack direction="column" gap={2} width={300}>
      <DeleteMessageText>
        {i18n.t(keys.deleteProjectPromptMessage1, {
          projectNoteCount,
          projectName,
        })}
      </DeleteMessageText>
      <DeleteMessageText>
        {i18n.t(keys.deleteProjectPromptMessage2)}
      </DeleteMessageText>
    </Stack>
  )
}
