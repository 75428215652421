import * as React from 'react'
import { PureComponent } from 'react'

import { Button, Icon, Tooltip } from '@mui/material'
import i18n, { keys } from '../i18n'
import { connect } from '../redux/connect'
import { updatePreferences } from '../redux/preferences/redux'
import { AppDispatchProps, RootStore } from '../redux/types'
import * as notes from './redux'

class CreateNoteButton extends PureComponent<ReduxProps & AppDispatchProps> {
  render() {
    return (
      <Button
        onClick={this.handleClick}
        disabled={this.props.isCreatingNote || this.props.isMeasureToolActive || this.props.isStatsZonesToolActive}>
        <Tooltip
          title={i18n.t(keys.notes.notesPopup.newNote)}
          placement="left"
        >
          <Icon>note_add</Icon>
        </Tooltip>
      </Button>
    )
  }

  handleClick = () => {
    this.props.dispatch(notes.actions.setIsCreatingNote(true))
    this.props.dispatch(updatePreferences({ showNotes: true }))
  }
}

const mapState = (state: RootStore) => ({
  isCreatingNote: state.notes.isCreatingNote,
  isMeasureToolActive: state.postgis.isMeasureToolActive,
  isStatsZonesToolActive: state.postgis.isStatsZonesToolActive,
})
type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps, {}, AppDispatchProps>(mapState)(
  CreateNoteButton
)
