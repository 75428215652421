import { DEFAULT_COVERAGE } from '../../ProductSettings/constants'
import { MapSourceProperty } from '../../graphql/types'
import {
  ClassInterval,
  createClassCombiner,
  createClassReducer,
  createIntervalCombiner,
  createIntervalReducer,
  ValueInterval,
} from './Reducer'
import { StatsData } from './statsData'

export interface ProductCoverage {
  [productId: string]: Record<string, number>
}

interface Reduced<T> {
  data: T[]
  noData: T[]
  coverage: T[]
}

export type category = keyof Reduced<any>

interface PerProduct<T> {
  type: MapSourceProperty['valueType']
  range?: MapSourceProperty['range']
  valueType: MapSourceProperty['valueType']
  valueUnit: MapSourceProperty['valueUnit']
  weightType: MapSourceProperty['valueType']
  weightUnit: MapSourceProperty['valueUnit']
  combined: Reduced<T>
  parcels: {
    [parcelId: string]: Reduced<T>
  }
}

export interface CategoryReduce {
  [productId: string]: PerProduct<ValueInterval> | PerProduct<ClassInterval>
}

export function categoryReduce(
  statsData: StatsData,
  productCoverage: ProductCoverage = {}
) {
  const result: CategoryReduce = {}

  for (const [productId, forProduct] of Object.entries(statsData)) {
    const groupId = Object.values(forProduct.parcels)[0].MapSource
      .DeliveryParcel?.Parcel?.groupId
    const date = Object.values(forProduct.parcels)[0].MapSource.flightDate

    let coverageValue =
      forProduct.layerDef.MapLayerSettings[0]?.coverage ?? DEFAULT_COVERAGE
    if (
      groupId &&
      date &&
      productCoverage[productId]?.[`${groupId}_${date}`] !== undefined
    ) {
      coverageValue = productCoverage[productId][`${groupId}_${date}`]
    }

    let valueType: MapSourceProperty['valueType'] = 'count'
    let valueUnit: MapSourceProperty['valueUnit'] = 'count'
    let weightType: MapSourceProperty['valueType'] = 'count'
    let weightUnit: MapSourceProperty['valueUnit'] = 'count'

    if (forProduct.layerDef.DataProperty) {
      valueType = forProduct.layerDef.DataProperty.valueType
      valueUnit = forProduct.layerDef.DataProperty.valueUnit
    }
    if (forProduct.layerDef.WeightProperty) {
      weightType = forProduct.layerDef.WeightProperty.valueType
      weightUnit = forProduct.layerDef.WeightProperty.valueUnit
    }

    const getValueAndWeight = (record: Record<string, any>) => {
      const valueProperty = forProduct.layerDef.DataProperty?.property

      const weightProperty = forProduct.layerDef.WeightProperty?.property

      return {
        value: record[`${valueProperty}`],
        weight: record[`${weightProperty}`],
      }
    }

    const getCategory = (record: Record<string, any>): category => {
      const noData = forProduct.layerDef.NoDataProperty
      const coverage = forProduct.layerDef.CoverageProperty

      if (noData && record[noData.property] === noData.noDataValue) {
        return 'noData'
      }

      if (coverage && record[coverage.property] <= coverageValue) {
        return 'coverage'
      }

      return 'data'
    }

    const type = forProduct.layerDef.DataProperty?.type

    const createReducer =
      type === 'value' ? createIntervalReducer : createClassReducer

    const createCombiner =
      type === 'value' ? createIntervalCombiner : createClassCombiner

    const combiners: Record<category, ReturnType<typeof createCombiner>> = {
      data: createCombiner(),
      noData: createCombiner(),
      coverage: createCombiner(),
    }

    const parcels: Record<string, any> = {}
    for (const [parcelId, forParcel] of Object.entries(forProduct.parcels)) {
      const reducers: Record<string, ReturnType<typeof createReducer>> = {
        data: createReducer(),
        noData: createReducer(),
        coverage: createReducer(),
      }

      for (const datum of forParcel.Properties ?? []) {
        const cat = getCategory(datum)
        const reducer = reducers[cat]

        reducer.update([getValueAndWeight(datum)])
      }

      const reduced: Record<string, ClassInterval[]> = {}
      for (const [cat, reducer] of Object.entries(reducers)) {
        reduced[cat] = reducer.digest()

        const combiner = combiners[cat]
        combiner.update(reduced[cat])
      }

      parcels[parcelId] = reduced
    }

    const combined: Record<string, ClassInterval[]> = {}
    for (const [cat, combiner] of Object.entries(combiners)) {
      combined[cat] = combiner.digest()
    }

    const range: [number, number] | undefined =
      type === 'value' && combined.data[0]
        ? [
            (combined.data[0] as any).min,
            (combined.data[combined.data.length - 1] as any).max,
          ]
        : undefined

    result[productId] = {
      range,
      parcels,
      valueType,
      weightType,
      valueUnit,
      weightUnit,
      type: type as any,
      combined: combined as any,
    }
  }

  return result
}
