import { Theme } from '@mui/material/styles'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { createStyles, withStyles, WithStyles } from '@mui/styles'
import * as React from 'react'
import { PureComponent } from 'react'
import { SidebarTabSelection } from '../app/types'
import i18n, { keys } from '../i18n'
import { NotesDrawer } from '../notes/NotesDrawer'
import { connect } from '../redux/connect'
import { updatePreferences } from '../redux/preferences/redux'
import { AppDispatchProps } from '../redux/types'
import ParcelDrawer from './ParcelDrawer/ParcelDrawer'

interface ReduxProps {
  selectedTab: SidebarTabSelection
}

class SidebarTabs extends PureComponent<
  ReduxProps & AppDispatchProps & WithStyles<typeof styles>
> {
  render() {
    const { classes } = this.props

    const tabContent =
      this.props.selectedTab === 'fields' ? <ParcelDrawer /> : <NotesDrawer />

    return (
      <>
        <div className="ui-nav no-print" id="sidebar-left">
          <Tabs
            value={this.props.selectedTab}
            textColor="primary"
            indicatorColor="primary"
            className={classes.root}
            variant="fullWidth"
            centered
            onChange={this.handleTabChange}
          >
            <Tab
              style={{ minWidth: 0 }}
              value="fields"
              label={i18n.t(keys.fieldsTab)}
            />
            <Tab
              style={{ minWidth: 0 }}
              value="notes"
              label={i18n.t(keys.notesTab)}
            />
          </Tabs>
        </div>
        <div className="ui-content no-print">{tabContent}</div>
      </>
    )
  }

  handleTabChange = (event: React.ChangeEvent<{}>, value: any) => {
    this.props.dispatch(updatePreferences({ sidebarTabLeft: value }))
  }
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flex: 1,
    },
  })

export default connect<ReduxProps, {}, AppDispatchProps>((state) => ({
  selectedTab: state.preferences.sidebarTabLeft,
}))(withStyles(styles)(SidebarTabs))
