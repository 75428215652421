import {
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  Icon,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
  styled,
  useTheme,
} from '@mui/material'
import * as React from 'react'
import { useNoteTemplates } from './NotesContext'
import i18n, { keys } from '../i18n'
import { setToggle } from '../util/setToggle'
import { Field, Note, NoteFormV2, NoteWithTemplate } from '../vvapi/models'
import { useRedux } from '../hooks/useRedux'
import { actions } from './redux'
import { DraggablePaper } from '../UI/DraggablePaper'
import { WindowPosition } from '../redux/preferences/types'
import { updatePreferences } from '../redux/preferences/redux'
import { SelectProject } from '../projects/SelectProject'
import { PinPointButtonPress } from './PinPointButtonPress'

export const TemplateFieldCard = styled(Card)(({ theme }) => ({
  '& .MuiCardHeader-root': {
    padding: theme.spacing(1),
    '& .MuiCardHeader-title': {
      ...theme.typography.caption,
    },
  },
  '& .MuiCardContent-root': {
    padding: theme.spacing(1),
  },
}))

const GeneralTextField = styled(TextField)(({ theme }) => ({
  mx: 0,
  '& textarea': {
    height: '100% !important',
  },
  '& fieldset': {
    borderWidth: 0,
    background: theme.palette.background.default,
  },
  '& .MuiInputAdornment-root': {
    zIndex: 2,
  },
  '& .MuiInputBase-root': {
    height: '100%',
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.grey[700],
      borderWidth: 1,
    },
  },
  '& .MuiInputBase-input': {
    p: 0.5,
    zIndex: 2,
  },
}))

export const CompactCardContent = styled(CardContent)(({ theme }) => ({
  ['&.MuiCardContent-root:last-child']: {
    paddingTop: 0,
    paddingBottom: theme.spacing(1),
  },
}))

export const CompactCardHeader = styled(CardHeader)(({ theme }) => ({
  ['&.MuiCardHeader-root']: {
    paddingTop: theme.spacing(1 / 2),
    paddingBottom: theme.spacing(1 / 4),
  },
}))

export interface FieldWithValue extends Field {
  value: string | number | string[] | boolean
}

interface Props {
  open: boolean
  initialNote?: NoteWithTemplate
  mode?: 'create' | 'edit'
  onCreateNote?: (
    note: Pick<Note, 'content' | 'templateId' | 'projectId'>
  ) => void
  onEditNote?: (note: Pick<Note, 'content' | 'projectId'>) => void
  onEditLocation?: (note: Pick<Note, 'content' | 'projectId'>) => void
  onCancel: () => void
  onDelete?: () => void
}

interface NoteState {
  isGeneral: boolean
  isPinPointButtonPress: boolean
  selectedTemplateId?: string
  pinColor?: string
  templateName?: string
  content: Record<string, FieldWithValue>
  selectedProjectId?: string
}

const GENERAL_TEMPLATE_ID = '00000000-0000-0000-0000-000000000000'
const PIN_POINT_BUTTON_PRESS_TEMPLATE_ID =
  '00000000-0000-0000-0000-000000000004'

const INITIAL_STATE: NoteState = {
  isGeneral: true,
  isPinPointButtonPress: false,
  selectedTemplateId: GENERAL_TEMPLATE_ID,
  pinColor: '#2196F3',
  templateName: i18n.t(keys.noteForm.reservedNames.__VV__GENERAL__TEMPLATE__),
  content: { note: { type: 'text', value: '' } },
}

type NoteAction =
  | {
      type: 'setNote'
      note: NoteWithTemplate
      isGeneral: boolean
      isPinPointButtonPress: boolean
    }
  | { type: 'setSelectedTemplate'; template: NoteFormV2 }
  | {
      type: 'setNoteValue'
      field: Omit<Field, 'title'>
      title: string
      value: any
    }
  | {
      type: 'setNoteCheckboxesValue'
      field: Omit<Field, 'title'>
      title: string
      value: any
    }
  | { type: 'reset' }
  | { type: 'setSelectedProject'; projectId: string | undefined }

const buildContentFromTemplate = (template: NoteFormV2) => {
  const content = {}

  for (const field of Object.values(template.fields)) {
    content[field.title!] = { ...field }
  }

  return content
}

const noteReducer = (state: NoteState, action: NoteAction) => {
  if (action.type === 'reset') {
    return INITIAL_STATE
  }
  if (action.type === 'setSelectedTemplate') {
    return {
      ...state,
      selectedTemplateId: action.template.id,
      isGeneral: action.template.id === GENERAL_TEMPLATE_ID,
      isPinPointButtonPress:
        action.template.id === PIN_POINT_BUTTON_PRESS_TEMPLATE_ID,
      content: buildContentFromTemplate(action.template),
      pinColor: action.template.pinColor,
      templateName: action.template.name,
    }
  }
  if (action.type === 'setNoteValue') {
    return {
      ...state,
      content: {
        ...state.content,
        [action.title]: { ...action.field, value: action.value },
      },
    }
  }
  if (action.type === 'setNoteCheckboxesValue') {
    const selectionSet = new Set(
      (state.content[action.title]?.value as string[]) ?? []
    )

    const value = Array.from(setToggle(selectionSet, action.value))

    return {
      ...state,
      content: {
        ...state.content,
        [action.title]: { ...action.field, value },
      },
    }
  }

  if (action.type === 'setNote') {
    return {
      content: action.note.content as Record<string, FieldWithValue>,
      isGeneral: action.isGeneral,
      isPinPointButtonPress: action.isPinPointButtonPress,
      pinColor: action.note.pinColor,
      templateName: action.note.templateName,
      selectedProjectId: action.note.projectId,
    } as NoteState
  }

  if (action.type === 'setSelectedProject') {
    return {
      ...state,
      selectedProjectId: action.projectId,
    }
  }

  return state
}

const NoPointerBackdrop = styled(Backdrop)({
  background: 'transparent',
  pointerEvents: 'none',
})

export const NoteForm = ({
  open,
  initialNote,
  onCreateNote,
  onCancel,
  onEditLocation,
  onEditNote,
  onDelete,
  mode = 'create',
}: Props) => {
  const theme = useTheme()
  const [state, dispatch] = useRedux()
  const [noteState, noteDispatch] = React.useReducer(
    noteReducer,
    INITIAL_STATE,
    () => {
      if (initialNote) {
        return {
          content: initialNote.content as Record<string, FieldWithValue>,
        } as NoteState
      } else {
        return INITIAL_STATE
      }
    }
  )

  React.useEffect(() => {
    if (!open && !initialNote) {
      setTimeout(() => {
        noteDispatch({ type: 'reset' })
      }, theme.transitions.duration.leavingScreen)
    }
  }, [theme.transitions.duration.leavingScreen, open, initialNote])

  const [noteTemplatesAsync] = useNoteTemplates()

  const templatesById = React.useMemo(
    () => noteTemplatesAsync?.result ?? ({} as Record<string, NoteFormV2>),
    [noteTemplatesAsync.result]
  )

  const generalTemplate = React.useMemo(
    () => templatesById[GENERAL_TEMPLATE_ID],
    [templatesById]
  )

  const pinPointTemplate = React.useMemo(
    () => templatesById[PIN_POINT_BUTTON_PRESS_TEMPLATE_ID],
    [templatesById]
  )

  React.useEffect(() => {
    if (initialNote && open) {
      noteDispatch({
        type: 'setNote',
        note: initialNote,
        isGeneral: initialNote.templateName === generalTemplate.name,
        isPinPointButtonPress:
          initialNote.templateName === pinPointTemplate.name,
      })
    }
  }, [initialNote, generalTemplate])

  React.useEffect(() => {
    if (open) {
      if (state.notes.createAnotherTemplateId) {
        noteDispatch({
          type: 'setSelectedTemplate',
          template: templatesById[state.notes.createAnotherTemplateId],
        })
      }
    }
  }, [open])

  const handleSaveEditNote = () => {
    onEditNote?.({
      content: noteState.content,
      projectId: noteState.selectedProjectId,
    })
  }

  const handleCreateNewNote = () => {
    const template = noteState.selectedTemplateId
      ? templatesById?.[noteState.selectedTemplateId]
      : undefined
    if (!template) {
      return
    }

    onCreateNote?.({
      content: noteState.content,
      templateId: template.id,
      projectId: noteState.selectedProjectId,
    })
  }

  const handleEditLocation = () => {
    onEditLocation?.({
      content: noteState.content,
      projectId: noteState.selectedProjectId,
    })
  }

  const renderTemplateForm = () => {
    // general template has special rendering
    if (noteState.isGeneral && !!generalTemplate) {
      const [noteField] = generalTemplate?.fields ?? []

      return (
        <GeneralTextField
          required
          autoComplete="off"
          autoFocus={mode === 'create'}
          multiline
          variant="outlined"
          placeholder={i18n.t(keys.notes.general.placeholder)}
          sx={{ height: 130, width: '100%' }}
          value={noteState.content[noteField.title!]?.value ?? ''}
          onChange={(ev) =>
            noteDispatch({
              type: 'setNoteValue',
              field: noteField,
              title: 'note',
              value: ev.target.value,
            })
          }
        />
      )
    }

    if (noteState.isPinPointButtonPress && !!pinPointTemplate) {
      return <PinPointButtonPress content={noteState.content} />
    }

    return (
      <Stack spacing={1}>
        {Object.entries(noteState.content)?.map(([fieldKey, field]) => {
          const translatedTitle = i18n.t(`noteForm.reservedNames.${fieldKey}`, {
            defaultValue: fieldKey,
          })

          return (
            <TemplateFieldCard
              key={fieldKey}
              sx={{ background: theme.palette.background.default }}
              elevation={0}
            >
              <CompactCardHeader
                title={translatedTitle}
                titleTypographyProps={{
                  fontWeight: '700 !important',
                  letterSpacing: 0.4,
                  px: 1 / 2,
                }}
              />
              <CompactCardContent>
                {renderField(fieldKey, translatedTitle, field)}
              </CompactCardContent>
            </TemplateFieldCard>
          )
        })}
      </Stack>
    )
  }

  const renderField = (
    fieldKey: string,
    fieldTitle: string,
    field: FieldWithValue
  ) => {
    switch (field.type) {
      case 'checkbox':
        return (
          <FormControlLabel
            label={fieldTitle}
            sx={{
              px: 1,
              '& .MuiTypography-root': {
                fontSize: 12,
              },
            }}
            key={fieldKey}
            control={
              <Checkbox
                sx={{ mx: 1, my: 0.5, p: 0 }}
                checked={(field.value as boolean) ?? false}
                onChange={() =>
                  noteDispatch({
                    type: 'setNoteValue',
                    field,
                    value: !field.value,
                    title: fieldKey,
                  })
                }
              />
            }
          />
        )
      case 'checkboxes':
        const selectionSet = new Set((field.value as string[]) ?? [])

        return (
          <FormControl component="fieldset" variant="outlined" sx={{ px: 1 }}>
            <FormGroup>
              {field.options?.map((option) => (
                <FormControlLabel
                  sx={{
                    '& .MuiTypography-root': {
                      fontSize: 12,
                    },
                  }}
                  key={`${fieldKey}_${option}`}
                  control={
                    <Checkbox
                      sx={{ mx: 1, my: 0.5, p: 0 }}
                      checked={selectionSet.has(option)}
                      onChange={() =>
                        noteDispatch({
                          type: 'setNoteCheckboxesValue',
                          field,
                          value: option,
                          title: fieldKey,
                        })
                      }
                    />
                  }
                  label={i18n.t(`noteForm.reservedNames.${option}`, {
                    defaultValue: option,
                  })}
                />
              ))}
            </FormGroup>
          </FormControl>
        )
      case 'dropdown':
        return (
          <Select
            fullWidth
            variant="outlined"
            sx={{ ['& .MuiSelect-select']: { p: 1 } }}
            value={field.value}
            onChange={(ev) =>
              noteDispatch({
                type: 'setNoteValue',
                field,
                title: fieldKey,
                value: ev.target.value,
              })
            }
          >
            {field.options?.map((option) => (
              <MenuItem key={`${fieldKey}_${option}`} value={option}>
                {i18n.t(`noteForm.reservedNames.${option}`, {
                  defaultValue: option,
                })}
              </MenuItem>
            ))}
          </Select>
        )
      case 'number':
        return (
          <TextField
            sx={{ ['& .MuiOutlinedInput-input']: { p: 1 } }}
            variant="outlined"
            fullWidth
            key={fieldKey}
            value={field.value ?? ''}
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(ev) =>
              noteDispatch({
                type: 'setNoteValue',
                field,
                title: fieldKey,
                value: Number(ev.target.value),
              })
            }
          />
        )
      case 'text':
        return (
          <FormControl key={fieldKey} fullWidth>
            <TextField
              sx={{ ['& .MuiInputBase-root']: { p: 1 } }}
              autoComplete="off"
              autoFocus={mode === 'create'}
              multiline
              fullWidth
              variant="outlined"
              value={field.value ?? ''}
              onChange={(ev) =>
                noteDispatch({
                  type: 'setNoteValue',
                  field,
                  title: fieldKey,
                  value: ev.target.value,
                })
              }
            />
          </FormControl>
        )
    }
  }

  const renderEditActions = () => {
    return (
      <Stack
        direction="row"
        width="100%"
        alignItems="center"
        justifyContent="end"
        spacing={1}
      >
        {!noteState.isPinPointButtonPress && <Button
          sx={{ minWidth: 0 }}
          variant="contained"
          fullWidth
          color="primary"
          onClick={handleSaveEditNote}
        >
          {i18n.t(keys.notes.notesPopup.save)}
        </Button>}

        {!noteState.isPinPointButtonPress && <Button
          sx={{ minWidth: 0 }}
          variant="contained"
          fullWidth
          onClick={handleEditLocation}
        >
          {i18n.t(keys.notes.notesPopup.editLocation)}
        </Button>}
        <IconButton onClick={onDelete}>
          <Icon>delete</Icon>
        </IconButton>
      </Stack>
    )
  }

  const renderNewNoteActions = () => (
    <Stack spacing={1} sx={{ width: '100%' }}>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={!!state.notes.createAnotherTemplateId}
              onChange={() =>
                dispatch(
                  actions.setCreateAnother(
                    !!state.notes.createAnotherTemplateId
                      ? undefined
                      : noteState.selectedTemplateId
                  )
                )
              }
            />
          }
          label={i18n.t(keys.notes.notesPopup.createAnother)}
        />
      </FormGroup>

      <Button
        variant="contained"
        fullWidth
        color="primary"
        onClick={handleCreateNewNote}
      >
        {i18n.t(keys.generic.create)}
      </Button>
    </Stack>
  )

  const handleTemplateChange = (ev: SelectChangeEvent) => {
    if (state.notes.createAnotherTemplateId && ev.target.value) {
      dispatch(actions.setCreateAnother(ev.target.value))
    }
    noteDispatch({
      type: 'setSelectedTemplate',
      template: templatesById[ev.target.value],
    })
  }

  const handleCancel = () => {
    onCancel()
    dispatch(actions.setCreateAnother(undefined))
  }

  const handleDialogPositionUpdate = (position: WindowPosition) => {
    dispatch(updatePreferences({ notesWindowPosition: position }))
  }

  return (
    <Dialog
      open={open}
      sx={{ pointerEvents: 'none' }}
      maxWidth="sm"
      PaperComponent={DraggablePaper}
      PaperProps={{
        position: state.preferences.notesWindowPosition,
        onPositionUpdate: handleDialogPositionUpdate,
      }}
      slots={{ backdrop: NoPointerBackdrop }}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle id="draggable-dialog-title" sx={{ cursor: 'move', px: 2 }}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h6">{i18n.t(keys.notesTab)}</Typography>
          <IconButton
            onClick={handleCancel}
            aria-label="close"
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.text.primary,
            }}
          >
            <Icon>close</Icon>
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ paddingBottom: 0, minWidth: 350, px: 2 }}>
        <Stack spacing={2}>
          {mode === 'edit' ? (
            noteState.isPinPointButtonPress ? (
              <Typography variant="h6">
                {(noteState.content as any).label!.value}
              </Typography>
            ) : (
              <Select
                disabled
                label="Template"
                value="NOT UNDEFINED"
                renderValue={() => (
                  <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center"
                    sx={{ paddingLeft: 0.5 }}
                  >
                    <Box
                      sx={{
                        height: 12,
                        width: 12,
                        borderRadius: 12,
                        background: `${noteState.pinColor}`,
                      }}
                    />
                    <ListItemText
                      primary={i18n.t(
                        `noteForm.reservedNames.${noteState.templateName}`,
                        {
                          defaultValue: noteState.templateName,
                        }
                      )}
                    />
                  </Stack>
                )}
              >
                <MenuItem value="NOT UNDEFINED" />
              </Select>
            )
          ) : (
            <Select
              label="Template"
              renderValue={() => (
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  sx={{ paddingLeft: 0.5 }}
                >
                  <Box
                    sx={{
                      height: 12,
                      width: 12,
                      borderRadius: 12,
                      background: `${
                        noteState.selectedTemplateId
                          ? templatesById?.[noteState.selectedTemplateId]
                              ?.pinColor
                          : undefined
                      }`,
                    }}
                  />
                  <ListItemText
                    primary={i18n.t(
                      `noteForm.reservedNames.${
                        noteState.selectedTemplateId
                          ? templatesById?.[noteState.selectedTemplateId]?.name
                          : undefined
                      }`,
                      {
                        defaultValue: noteState.selectedTemplateId
                          ? templatesById?.[noteState.selectedTemplateId]?.name
                          : undefined,
                      }
                    )}
                  />
                </Stack>
              )}
              value={noteState.selectedTemplateId ?? ''}
              onChange={handleTemplateChange}
            >
              {Object.values(templatesById).map((template) => {
                if (template.id === PIN_POINT_BUTTON_PRESS_TEMPLATE_ID) {
                  return null
                }

                return (
                  <MenuItem key={template.id} value={template.id}>
                    <ListItemIcon>
                      <Box
                        sx={{
                          height: 12,
                          width: 12,
                          borderRadius: 12,
                          background: `${template.pinColor}`,
                        }}
                      />
                    </ListItemIcon>

                    <ListItemText
                      primary={i18n.t(
                        `noteForm.reservedNames.${template.name}`,
                        {
                          defaultValue: template.name,
                        }
                      )}
                    />
                  </MenuItem>
                )
              })}
            </Select>
          )}
          <SelectProject
            selectedProjectId={noteState.selectedProjectId}
            type="notes"
            onProjectChange={(projectId) =>
              noteDispatch({ type: 'setSelectedProject', projectId })
            }
          />
          {renderTemplateForm()}
        </Stack>
      </DialogContent>
      <DialogActions sx={{ px: 2 }}>
        {mode === 'edit' ? renderEditActions() : renderNewNoteActions()}
      </DialogActions>
    </Dialog>
  )
}
